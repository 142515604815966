import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DETECTION_STATUS, User } from '../../interfaces/user.interface';
import { Subscription } from 'rxjs';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { ThemeService } from '../../services/theme.service';
import { ToastController } from '@ionic/angular';
import { ChatSupporterService } from '../../services/chat-supporter.service';
import { filter } from 'rxjs/operators';
import { Modal } from '../../interfaces/modal.interface';
import { SupportList } from '../../interfaces/supportList.interface';
import { SupportListOwnerService } from '../../services/support-list-owner.service';
import { VoterService } from 'src/app/services/voter.service';

import * as _ from 'lodash';

type ExtUser = User & { isMember: Boolean };

@Component({
  selector: 'app-modal-support-list',
  templateUrl: './modal-support-list.component.html',
  styleUrls: ['./modal-support-list.component.scss'],
})
export class ModalSupportListComponent implements OnInit {
  public loadedVoters: ExtUser[];
  private voterChunks: ExtUser[][];
  public currentChunk: number;
  public currentStep: number;
  public availableSupportListOwners: ExtUser[];
  public supportListOwner: User;
  public availableChatSupporters: ExtUser[];
  public chatSupporters: User[];
  public availableDetectedVoters: ExtUser[];
  public detectedVoters: User[];
  public supportListForm: FormGroup;
  private detectedVotersEvent: Subscription;
  private payload: SupportList;
  private supportListOwnerEvent: Subscription;
  private chatSupportersEvent: Subscription;
  private modalEvent: Subscription;

  @ViewChild('accordion', { static: false })
  public accordion: NgbAccordion;

  constructor(
    private readonly themeService: ThemeService,
    private readonly toastController: ToastController,
    private readonly supportListOwnerService: SupportListOwnerService,
    private readonly chatSupporterService: ChatSupporterService,
    private readonly voterService: VoterService
  ) {
    this.currentChunk = 0;
    this.currentStep = 0;
    this.loadedVoters = [];
    this.voterChunks = [];
    this.availableSupportListOwners = [];
    this.availableChatSupporters = [];
    this.availableDetectedVoters = [];
    this.chatSupporters = [];
    this.detectedVoters = [];
    this.handleServerData();
  }

  private handleServerData(): void {
    this.supportListOwnerEvent = this.supportListOwnerService.supportListOwners$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data) => {
        data = _.cloneDeep(data);
        this.availableSupportListOwners = data.map((user) => {
          return { ...user, isMember: false };
        });
      });

    this.chatSupportersEvent = this.chatSupporterService.chatSupporter$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data) => {
        data = _.cloneDeep(data);
        this.availableChatSupporters = data.map((user) => {
          return { ...user, isMember: false };
        });
      });

    this.detectedVotersEvent = this.voterService.voters$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data) => {
        this.availableDetectedVoters = _.sortBy(
          _.cloneDeep(data).map((item) => {
            const title = Number(item.title);
            item.title = title ? (title as any) : 0;
            return item;
          }),
          'title'
        )
          .filter((user) => {
            return (
              user.status.detectionStatus.status == DETECTION_STATUS['DETECTED']
            );
          })
          .map((user) => {
            return { ...user, isMember: false };
          });
        this.voterChunks = _.chunk(this.availableDetectedVoters, 100);
        this.loadedVoters = this.replaceVoters(
          this.loadedVoters,
          _.flatten(_.take(this.voterChunks, this.currentChunk + 1))
        );
      });

    this.modalEvent = this.themeService.isModal$
      .pipe(
        filter(({ isOpen }: Modal) => {
          return isOpen;
        })
      )
      .subscribe(({ payload }) => {
        this.payload = _.cloneDeep(payload);
      });
  }

  private replaceVoters(source: ExtUser[], update: ExtUser[]): ExtUser[] {
    source = _.uniqBy([...source, ...update], '_id');
    source = _.intersectionBy(source, update, '_id');
    for (let voter of source) {
      let updateItem = _.find(update, { _id: voter._id });
      if (updateItem) _.merge(voter, _.omit(updateItem, '_id'));
    }
    return source;
  }

  public onLoadData(): void {
    if (this.voterChunks) {
      this.loadedVoters.push(...this.voterChunks[this.currentChunk]);
      this.currentChunk++;
    }
  }

  public async onCancel(): Promise<void> {
    this.themeService.isModal$.next({
      name: 'support-list',
      isOpen: false,
      returns: null,
      action: 'nothing',
    });

    const toast = await this.toastController.create({
      message: 'Saliste del editor, la información fue descartada',
      color: 'warning',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  public async onContinueError(): Promise<void> {
    const toast = await this.toastController.create({
      message: 'No haz completado la información correctamente',
      color: 'danger',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  public async onContinue(): Promise<void> {
    let returns = {
      name: this.supportListForm.value.name,
      supportListOwners: [this.supportListOwner._id],
      chatSupporters: this.chatSupporters.map((user) => {
        return user._id;
      }) as any,
      voters: this.detectedVoters.map((user) => {
        return user._id;
      }) as any,
    } as SupportList;

    if (this.payload) returns._id = this.payload._id;

    this.themeService.isModal$.next({
      name: 'support-list',
      isOpen: false,
      returns,
      action: this.payload ? 'update' : 'create',
    });
  }

  public onSupportListOwner(data: any, isMember: boolean): void {
    this.availableSupportListOwners = this.availableSupportListOwners.map(
      (user) => {
        return { ...user, ...{ isMember: false } };
      }
    );
    this.availableSupportListOwners.map((user) => {
      if (user._id == data._id) {
        user.isMember = !isMember;
        this.supportListOwner = user;
      }
    });
  }

  public onChatSupporter(data: any, isMember: boolean): void {
    this.chatSupporters = [];
    this.availableChatSupporters.map((chatSupporter) => {
      if (chatSupporter._id == data._id) chatSupporter.isMember = !isMember;
      if (chatSupporter.isMember) this.chatSupporters.push(chatSupporter);
    });
  }

  public onDetectedVoter(data: any, isMember: boolean): void {
    this.detectedVoters = [];
    this.availableDetectedVoters.map((voter) => {
      if (voter._id == data._id) voter.isMember = !isMember;
      if (voter.isMember) this.detectedVoters.push(voter);
    });
  }

  private initForm(): void {
    this.supportListForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
    });
  }

  ngAfterViewInit(): void {
    this.accordion.expand(`ngb-panel-${this.currentStep}`);
  }

  ngOnInit(): void {
    this.initForm();
    if (this.payload) {
      this.supportListForm.patchValue({
        name: this.payload.name,
      });

      this.availableChatSupporters = this.availableChatSupporters.map(
        (user) => {
          if (_.find(this.payload.chatSupporters, { _id: user._id })) {
            user.isMember = true;
            this.chatSupporters.push(user);
          }
          return user;
        }
      );

      this.availableSupportListOwners = this.availableSupportListOwners.map(
        (user) => {
          if (_.find(this.payload.supportListOwners, { _id: user._id })) {
            user.isMember = true;
            this.supportListOwner = user;
          }
          return user;
        }
      );

      this.availableDetectedVoters = this.availableDetectedVoters.map(
        (user) => {
          if (_.find(this.payload.voters, { _id: user._id })) {
            user.isMember = true;
            this.detectedVoters.push(user);
          }
          return user;
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.modalEvent.unsubscribe();
    this.supportListOwnerEvent.unsubscribe();
    this.detectedVotersEvent.unsubscribe();
    this.chatSupportersEvent.unsubscribe();
  }
}
