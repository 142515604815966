import { environment } from 'src/environments/environment';
import { ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { SocketNameSpace } from '../socket.namespace';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';
import { Response } from '../interfaces/response.interface';
import { Auth, User } from '../interfaces/user.interface';
import { AuthService } from './auth.service';
import { ThemeService } from './theme.service';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SupportListOwnerService {
  public supportListOwners$: BehaviorSubject<User[]>;
  private socket: Socket;
  private auth: Auth;

  constructor(
    private readonly authService: AuthService,
    private readonly themeService: ThemeService,
    private readonly toastController: ToastController
  ) {
    this.supportListOwners$ = new BehaviorSubject(null);
    this.socket = new SocketNameSpace({
      url: `${environment.server.base_url}/support-list-owner`,
      options: {},
    });
    this.observeServerData();
    this.observePrivateResponses();
    this.observeModals();
  }

  private observeServerData(): void {
    this.authService.auth$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data: Auth) => {
        this.auth = data;
        this.socket.emit('support-list-owner-server:findAll', {
          token: this.auth.token,
        });
        this.socket
          .fromEvent('support-list-owner-client:findAll')
          .subscribe(({ data }: Response<User[]>) => {
            this.supportListOwners$.next(data);
          });
      });
  }

  private observePrivateResponses(): void {
    this.socket
      .fromEvent('support-list-owner-client:createOne')
      .subscribe(async ({ data }: Response<User>) => {
        const toast = await this.toastController.create({
          message: `El movilizador ${data.firstName} se ha registrado, todos los sistemas han sido notificados`,
          color: 'success',
          position: 'top',
          mode: 'ios',
          duration: 6000,
        });
        toast.present();
      });
    this.socket
      .fromEvent('support-list-owner-client:deleteOne')
      .subscribe(async () => {
        const toast = await this.toastController.create({
          message: `El movilizador se ha eliminado, todos los sistemas han sido notificados`,
          color: 'success',
          position: 'top',
          mode: 'ios',
          duration: 6000,
        });
        toast.present();
      });
  }

  private observeModals(): void {
    this.themeService.isModal$
      .pipe(
        filter(
          (modal) =>
            modal.name == 'support-list-owner' &&
            modal.isOpen == false &&
            modal.returns != null
        )
      )
      .subscribe(({ action, returns }) => {
        if (action == 'create') this.createOne(returns);
        else if (action == 'update') this.updateOne(returns);
      });

    this.themeService.isModal$
      .pipe(
        filter(
          (modal) =>
            modal.name == 'confirm' &&
            modal.isOpen == false &&
            modal.action == 'delete' &&
            modal.returns != null &&
            modal.returns.startsWith('delete-support-list-owner/')
        )
      )
      .subscribe(({ returns }) => {
        this.deleteOne(_.last(returns.split('/')));
      });
  }

  private createOne(user: User): void {
    user.access = [];
    for (let access of this.auth.access) user.access.push(access);
    this.socket.emit('support-list-owner-server:createOne', {
      ...user,
      token: this.auth.token,
    });
  }

  private updateOne(user: User) {
    this.socket.emit('support-list-owner-server:updateOne', {
      ...user,
      token: this.auth.token,
    });
  }

  private deleteOne(id: string): void {
    this.socket.emit('support-list-owner-server:deleteOne', {
      _id: id,
      token: this.auth.token,
    });
  }
}
