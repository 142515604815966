import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { VoterService } from '../../services/voter.service';
import { DETECTION_STATUS, User } from 'src/app/interfaces/user.interface';
import * as _ from 'lodash';

@Component({
  selector: 'app-voters',
  templateUrl: './voters.component.html',
  styleUrls: ['./voters.component.scss'],
})
export class VotersComponent implements OnInit {
  public voters: User[];
  public loadedVoters: User[];
  private voterChunks: User[][];
  public currentChunk: number;
  public detectedVoters: number;
  public assignedLists: number;
  private votersSubscription: Subscription;

  constructor(
    private readonly voterService: VoterService,
    private readonly themeService: ThemeService
  ) {
    this.voters = [];
    this.loadedVoters = [];
    this.voterChunks = [];
    this.currentChunk = 0;
    this.detectedVoters = 0;
    this.assignedLists = 0;
    this.handleDataService();
  }

  private async handleDataService() {
    this.votersSubscription = this.voterService.voters$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data: User[]) => {
        this.voters = _.sortBy(
          _.cloneDeep(data).map((item) => {
            const title = Number(item.title);
            item.title = title ? (title as any) : 0;
            return item;
          }),
          'title'
        );
        this.voterChunks = _.chunk(this.voters, 100);
        this.loadedVoters = this.replaceVoters(
          this.loadedVoters,
          _.flatten(_.take(this.voterChunks, this.currentChunk + 1))
        );

        [this.detectedVoters, this.assignedLists] = this.voters
          .map(({ supportList, status }: User) => {
            return [
              status.detectionStatus.status == DETECTION_STATUS['DETECTED']
                ? 1
                : 0,
              supportList == null ? 0 : 1,
            ];
          })
          .reduce(
            (a, b) => {
              return [a[0] + b[0], a[1] + b[1]];
            },
            [0, 0]
          );
      });
  }

  private replaceVoters(source: User[], update: User[]): User[] {
    source = _.uniqBy([...source, ...update], '_id');
    source = _.intersectionBy(source, update, '_id');
    for (let voter of source) {
      let updateItem = _.find(update, { _id: voter._id });
      if (updateItem) _.merge(voter, _.omit(updateItem, '_id'));
    }
    return source;
  }

  public onVoter(voter?: User): void {
    this.themeService.isModal$.next({
      name: 'voter',
      isOpen: true,
      payload: voter,
      action: voter ? 'update' : 'create',
    });
  }

  public onDetected(): void {
    this.themeService.isModal$.next({
      name: 'detected-voters',
      isOpen: true,
      action: 'update',
    });
  }

  public onDelete(voter?: User): void {
    this.themeService.isModal$.next({
      name: 'confirm',
      isOpen: true,
      payload: `delete-voter/${voter._id}`,
      action: 'delete',
    });
  }

  public onLoadData(): void {
    if (this.voterChunks) {
      this.loadedVoters.push(...this.voterChunks[this.currentChunk]);
      this.currentChunk++;
    }
  }

  public onScroll(event) {}

  ngOnInit(): void {
    this.voterChunks = _.chunk(this.voters, 100);
  }

  ngOnDestroy(): void {
    this.votersSubscription.unsubscribe();
  }
}
