import { Pipe, PipeTransform } from '@angular/core';
import { ROLES } from '../interfaces/user.interface';

@Pipe({
  name: 'role',
})
export class RolePipe implements PipeTransform {
  transform(value: ROLES): string {
    switch (ROLES[value]) {
      case 'MASTER':
        return 'Administrador Principal';
      case 'ADMIN':
        return 'Administrador';
      case 'POLL_OWNER':
        return 'Casillero';
      case 'VOTER':
        return 'Elector';
      case 'SUPPORT_LIST_OWNER':
        return 'Movilizador';
      case 'BALLOT_OWNER':
        return 'Recolector';
      case 'CHAT_SUPPORTER':
        return 'Soporte en Chat';
      default:
        return 'Ninguno';
    }
  }
}
