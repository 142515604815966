import { Component, OnInit, Input } from '@angular/core';
import { Poll } from 'src/app/interfaces/poll.interface';
import { DETECTION_STATUS, User } from 'src/app/interfaces/user.interface';

import * as _ from 'lodash';

@Component({
  selector: 'app-polls-item',
  templateUrl: './polls-item.component.html',
  styleUrls: ['./polls-item.component.scss'],
})
export class PollsItemComponent implements OnInit {
  @Input('poll')
  public poll: Poll;
  public detectedVoters: number;

  constructor() {
    this.detectedVoters = 0;
  }

  public take(array: any, n: number): any[] {
    return _.takeRight(array, n);
  }

  ngOnInit() {
    this.detectedVoters = _.filter(this.poll.voters, (user: User) => {
      return user.status.detectionStatus.status == DETECTION_STATUS['DETECTED'];
    }).length;
  }
}
