import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../services/theme.service';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit {
  public isNotification: boolean;
  constructor(private readonly themeService: ThemeService) {
    this.themeService.isNotification$
      .pipe(distinctUntilChanged())
      .subscribe((value: boolean) => {
        this.isNotification = value;
      });
  }

  ngOnInit() {}
}
