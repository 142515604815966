import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ThemeService } from '../../services/theme.service';
import { Modal } from '../../interfaces/modal.interface';
import { Section } from '../../interfaces/section.interface';

import * as _ from 'lodash';

@Component({
  selector: 'app-modal-section',
  templateUrl: './modal-section.component.html',
  styleUrls: ['./modal-section.component.scss'],
})
export class ModalSectionComponent implements OnInit {
  public polls = [];

  public payload: Section;
  public sectionForm: FormGroup;
  private modalEvent: Subscription;
  constructor(
    private readonly themeService: ThemeService,
    private readonly toastController: ToastController
  ) {
    this.modalEvent = this.themeService.isModal$.subscribe(
      ({ payload }: Modal) => {
        this.payload = _.cloneDeep(payload);
      }
    );
  }

  public async onCancel(): Promise<void> {
    this.themeService.isModal$.next({
      name: 'section',
      action: 'nothing',
      isOpen: false,
      returns: null,
    });

    const toast = await this.toastController.create({
      message: 'Saliste del editor, la información fue descartada',
      color: 'warning',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  public async onContinueError(): Promise<void> {
    const toast = await this.toastController.create({
      message: 'No haz completado la información correctamente',
      color: 'danger',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  public async onContinue(): Promise<void> {
    const formPayload = this.sectionForm.value;
    delete formPayload.confirmPassword;
    this.themeService.isModal$.next({
      name: 'section',
      isOpen: false,
      returns: this.payload
        ? { _id: this.payload._id, ...formPayload }
        : formPayload,
      action: this.payload ? 'update' : 'create',
    });
  }

  private initForm(): void {
    this.sectionForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.initForm();
    if (this.payload) this.sectionForm.patchValue(this.payload);
  }

  ngOnDestroy(): void {
    this.modalEvent.unsubscribe();
  }
}
