import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ComponentsModule } from '../components/components.module';

import { ModalAccessComponent } from './modal-access/modal-access.component';
import { ModalSectionComponent } from './modal-section/modal-section.component';
import { PagesModule } from '../pages/pages.module';
import { ModalPollOwnerComponent } from './modal-poll-owner/modal-poll-owner.component';
import { ModalChatSupporterComponent } from './modal-chat-supporter/modal-chat-supporter.component';
import { ModalsComponent } from './modals.component';
import { ModalPollComponent } from './modal-poll/modal-poll.component';
import { ModalAdminComponent } from './modal-admin/modal-admin.component';
import { ModalVoterComponent } from './modal-voter/modal-voter.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { ModalDetectedVotersComponent } from './modal-detected-voters/modal-detected-voters.component';
import { ModalSupportListComponent } from './modal-support-list/modal-support-list.component';
import { ModalSupportListOwnerComponent } from './modal-support-list-owner/modal-support-list-owner.component';
import { ModalPartyComponent } from './modal-party/modal-party.component';
import { ModalBallotOwnerComponent } from './modal-ballot-owner/modal-ballot-owner.component';
import { ModalBallotComponent } from './modal-ballot/modal-ballot.component';
import { AgmCoreModule } from '@agm/core';
import { ModalMapsComponent } from './modal-maps/modal-maps.component';
import { ModalStatsComponent } from './modal-stats/modal-stats.component';

import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  imports: [
    AgmCoreModule,
    NgxFileDropModule,
    NgbModule,
    IonicModule,
    RouterModule,
    PagesModule,
    PipesModule,
    CommonModule,
    ComponentsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ModalsComponent,
    ModalAccessComponent,
    ModalAdminComponent,
    ModalBallotComponent,
    ModalBallotOwnerComponent,
    ModalChatSupporterComponent,
    ModalConfirmComponent,
    ModalDetectedVotersComponent,
    ModalPartyComponent,
    ModalPollComponent,
    ModalPollOwnerComponent,
    ModalSectionComponent,
    ModalSupportListComponent,
    ModalSupportListOwnerComponent,
    ModalVoterComponent,
    ModalMapsComponent,
    ModalStatsComponent,
  ],
  exports: [
    ModalsComponent,
    ModalAccessComponent,
    ModalAdminComponent,
    ModalBallotComponent,
    ModalBallotOwnerComponent,
    ModalChatSupporterComponent,
    ModalConfirmComponent,
    ModalDetectedVotersComponent,
    ModalPartyComponent,
    ModalPollComponent,
    ModalPollOwnerComponent,
    ModalSectionComponent,
    ModalSupportListComponent,
    ModalSupportListOwnerComponent,
    ModalVoterComponent,
    ModalMapsComponent,
    ModalStatsComponent,
  ],
})
export class ModalsModule {}
