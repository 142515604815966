import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleComponent } from './title/title.component';
import { BrandPanComponent } from './brand-pan/brand-pan.component';
import { BrandCuadrosoftComponent } from './brand-cuadrosoft/brand-cuadrosoft.component';
import { BrandHugoComponent } from './brand-hugo/brand-hugo.component';
import { InputComponent } from './input/input.component';
import { IconComponent } from './icon/icon.component';
import { CheckBoxComponent } from './check-box/check-box.component';
import { ButtonComponent } from './button/button.component';
import { AvatarComponent } from './avatar/avatar.component';
import { AvatarIconComponent } from './avatar-icon/avatar-icon.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [
    TitleComponent,
    BrandPanComponent,
    BrandCuadrosoftComponent,
    BrandHugoComponent,
    InputComponent,
    IconComponent,
    CheckBoxComponent,
    ButtonComponent,
    AvatarComponent,
    AvatarIconComponent,
    DropdownComponent,
  ],
  exports: [
    TitleComponent,
    BrandPanComponent,
    BrandCuadrosoftComponent,
    BrandHugoComponent,
    InputComponent,
    IconComponent,
    CheckBoxComponent,
    ButtonComponent,
    AvatarComponent,
    AvatarIconComponent,
    DropdownComponent,
  ],
  imports: [CommonModule, NgbModule, PipesModule],
})
export class ComponentsModule {}
