import { Component, Input, OnInit } from '@angular/core';
import { DETECTION_STATUS, User } from 'src/app/interfaces/user.interface';
import { Poll } from 'src/app/interfaces/poll.interface';
import { Section } from 'src/app/interfaces/section.interface';

import * as _ from 'lodash';

@Component({
  selector: 'app-section-item',
  templateUrl: './section-item.component.html',
  styleUrls: ['./section-item.component.scss'],
})
export class SectionItemComponent implements OnInit {
  @Input('section')
  public section: Section;
  public pollOwners: User[];
  public detectedVoters: number;

  constructor() {
    this.pollOwners = [];
    this.detectedVoters = 0;
  }

  public take(array: any[], n: number): any[] {
    return _.takeRight(array, n);
  }

  ngOnInit() {
    this.detectedVoters = this.section.voters
      .map((voter) => {
        return voter.status.detectionStatus.status ==
          DETECTION_STATUS['DETECTED']
          ? 1
          : 0;
      })
      .reduce((a, b) => {
        return a + b;
      }, 0);

    this.pollOwners = _.orderBy(
      _.flatten(
        this.section.polls.map((poll: Poll): User[] => {
          return poll.pollOwners;
        })
      ),
      ['status.networkStatus.status'],
      ['asc']
    );
  }
}
