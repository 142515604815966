import { Component, OnInit } from '@angular/core';
import { SectionService } from '../../services/section.service';
import { ThemeService } from '../../services/theme.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Section } from '../../interfaces/section.interface';
import { Poll } from '../../interfaces/poll.interface';

import * as _ from 'lodash';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss'],
})
export class SectionsComponent implements OnInit {
  public sections: Section[];
  public assignedPolls: number;
  public assignedVoters: number;
  private sectionSubscription: Subscription;

  constructor(
    private readonly sectionService: SectionService,
    private readonly themeService: ThemeService
  ) {
    this.sections = [];
    this.assignedPolls = 0;
    this.assignedVoters = 0;
    this.handleDataService();
  }

  private handleDataService() {
    this.sectionSubscription = this.sectionService.sections$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data: Section[]) => {
        data = _.cloneDeep(data);
        this.sections = _.uniqBy([...this.sections, ...data], '_id');
        this.sections = _.intersectionBy(this.sections, data, '_id');
        for (const section of this.sections) {
          const update = _.find(data, { _id: section._id });
          if (update) _.merge(section, _.omit(update, '_id'));
        }

        [this.assignedPolls, this.assignedVoters] = data
          .map(({ polls }: Section) => {
            return [
              polls.length,
              polls
                .map(({ voters }: Poll) => {
                  return voters.length;
                })
                .reduce((a, b) => {
                  return a + b;
                }, 0),
            ];
          })
          .reduce(
            (a, b) => {
              return [a[0] + b[0], a[1] + b[1]];
            },
            [0, 0]
          );
      });
  }

  public onSection(section?: Section): void {
    this.themeService.isModal$.next({
      name: 'section',
      payload: _.cloneDeep(section),
      isOpen: true,
      action: section ? 'update' : 'create',
    });
  }

  public onDelete(section: Section): void {
    this.themeService.isModal$.next({
      name: 'confirm',
      isOpen: true,
      payload: `delete-section/${section._id}`,
      action: 'delete',
    });
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.sectionSubscription.unsubscribe();
  }
}
