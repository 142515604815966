import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Modal } from '../interfaces/modal.interface';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public isNotification$: BehaviorSubject<boolean>;

  public isModal$: BehaviorSubject<Modal>;

  constructor(private readonly router: Router) {
    this.isModal$ = new BehaviorSubject({ isOpen: false, action: 'nothing' });

    this.isModal$.subscribe((value) => {
      if (value && value.isOpen)
        this.router.navigate([{ outlets: { modal: 'modal/' + value.name } }]);
      else this.router.navigate([{ outlets: { modal: null } }]);
    });

    this.isNotification$ = new BehaviorSubject<boolean>(
      environment.theme.startNotificationPanel
    );
  }
}
