import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent implements OnInit {
  @Input('title')
  public title: string;

  @Input('subtitle')
  public subtitle: string;

  @Input('size')
  public size: string;

  @Input('dark')
  public dark: boolean;

  constructor() {
    this.dark = false;
  }

  ngOnInit() {}
}
