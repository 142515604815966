import { Component, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { ThemeService } from 'src/app/services/theme.service';
import { Modal } from 'src/app/interfaces/modal.interface';

import * as _ from 'lodash';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent implements OnInit {
  public payload: string;
  public action: Modal['action'];
  private modalEvent: Subscription;

  constructor(
    private readonly themeService: ThemeService,
    private readonly toastController: ToastController
  ) {
    this.modalEvent = this.themeService.isModal$.subscribe(
      ({ payload, action }: Modal) => {
        this.payload = _.cloneDeep(payload);
        this.action = action;
      }
    );
  }

  public async onCancel(): Promise<void> {
    this.themeService.isModal$.next({
      name: 'confirm',
      isOpen: false,
      returns: false,
      action: 'nothing',
    });
  }

  public async onContinue(): Promise<void> {
    this.themeService.isModal$.next({
      name: 'confirm',
      isOpen: false,
      returns: this.payload,
      action: this.action,
    });
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.modalEvent.unsubscribe();
  }
}
