import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';
import { ComponentsModule } from '../components/components.module';
import { PipesModule } from '../pipes/pipes.module';
import { ReactiveFormsModule } from '@angular/forms';

import { PagesComponent } from './pages.component';
import { MapsComponent } from './maps/maps.component';
import { ChatsComponent } from './chats/chats.component';
import { PollsComponent } from './polls/polls.component';
import { StatsComponent } from './stats/stats.component';
import { UsersComponent } from './users/users.component';
import { ChatComponent } from './chats/chat/chat.component';
import { NavbarComponent } from './navbar/navbar.component';
import { VotersComponent } from './voters/voters.component';
import { BallotsComponent } from './ballots/ballots.component';
import { PartiesComponent } from './parties/parties.component';
import { SectionsComponent } from './sections/sections.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { SupportersComponent } from './supporters/supporters.component';
import { UserItemComponent } from './users/user-item/user-item.component';
import { ChatBarComponent } from './chats/chat/chat-bar/chat-bar.component';
import { PollsItemComponent } from './polls/polls-item/polls-item.component';
import { VoterItemComponent } from './voters/voter-item/voter-item.component';
import { PartyItemComponent } from './parties/party-item/party-item.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { BallotItemComponent } from './ballots/ballot-item/ballot-item.component';
import { SectionItemComponent } from './sections/section-item/section-item.component';
import { ChatMessageComponent } from './chats/chat/chat-message/chat-message.component';
import { SupporterItemComponent } from './supporters/supporter-item/supporter-item.component';
import { ChatContactItemComponent } from './chats/chat-contact-item/chat-contact-item.component';
import { NotificationItemComponent } from './notifications/notification-item/notification-item.component';

@NgModule({
  imports: [
    RouterModule,
    ReactiveFormsModule,
    CommonModule,
    SwiperModule,
    ComponentsModule,
    IonicModule,
    PipesModule,
  ],
  declarations: [
    BallotItemComponent,
    BallotsComponent,
    ChatBarComponent,
    ChatComponent,
    ChatContactItemComponent,
    ChatMessageComponent,
    ChatsComponent,
    MapsComponent,
    NavbarComponent,
    NotificationItemComponent,
    NotificationsComponent,
    PagesComponent,
    PartiesComponent,
    PartyItemComponent,
    PollsComponent,
    PollsItemComponent,
    SectionItemComponent,
    SectionsComponent,
    SideMenuComponent,
    StatsComponent,
    SupporterItemComponent,
    SupportersComponent,
    UserItemComponent,
    UsersComponent,
    VoterItemComponent,
    VotersComponent,
  ],
  exports: [
    BallotItemComponent,
    BallotsComponent,
    ChatBarComponent,
    ChatComponent,
    ChatContactItemComponent,
    ChatMessageComponent,
    ChatsComponent,
    MapsComponent,
    NavbarComponent,
    NotificationItemComponent,
    NotificationsComponent,
    PagesComponent,
    PartiesComponent,
    PartyItemComponent,
    PollsComponent,
    PollsItemComponent,
    SectionItemComponent,
    SectionsComponent,
    SideMenuComponent,
    StatsComponent,
    SupporterItemComponent,
    SupportersComponent,
    UserItemComponent,
    UsersComponent,
    VoterItemComponent,
    VotersComponent,
  ],
})
export class PagesModule {}
