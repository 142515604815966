import { environment } from 'src/environments/environment';
import { ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { SocketNameSpace } from '../socket.namespace';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';
import { Response } from '../interfaces/response.interface';
import { Auth } from '../interfaces/user.interface';
import { AuthService } from './auth.service';
import { ThemeService } from './theme.service';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { Chat, ChatMessage } from '../interfaces/chat.interface';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  public chats$: BehaviorSubject<Chat[]>;
  public author: string;
  private socket: Socket;
  private auth: Auth;

  constructor(
    private readonly authService: AuthService,
    private readonly themeService: ThemeService,
    private readonly toastController: ToastController
  ) {
    this.chats$ = new BehaviorSubject(null);
    this.socket = new SocketNameSpace({
      url: `${environment.server.base_url}/chat`,
      options: {},
    });
    this.observeServerData();
    this.observePrivateResponses();
  }

  private observeServerData(): void {
    this.authService.auth$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data: Auth) => {
        this.auth = data;
        this.author = data._id;
        this.socket.emit('chat-server:findAll', {
          token: this.auth.token,
        });
        this.socket
          .fromEvent('chat-client:findAll')
          .subscribe(({ data }: Response<Chat[]>) => {
            this.chats$.next(data);
          });
      });
  }

  private observePrivateResponses(): void {
    this.socket.fromEvent('chat-client:updateOne').subscribe(async () => {
      const toast = await this.toastController.create({
        message: `Mensaje enviado con éxito, todos los sistemas han sido notificados`,
        color: 'success',
        position: 'top',
        mode: 'ios',
        duration: 6000,
      });
      toast.present();
    });
  }

  public sendMessage(chat: Chat, message: string) {
    this.socket.emit('chat-server:createMessage', {
      chat_id: chat._id,
      canSee: chat.members.map((user) => {
        return user._id;
      }),
      message: message,
      seenBy: chat.members.map((user) => {
        return user._id;
      }),
      sender: this.author,
      token: this.auth.token,
    });
  }

  public editMessage(chat: Chat, message: ChatMessage) {
    this.socket.emit('chat-server:createMessage', {
      chat_id: chat._id,
      _id: message._id,
      message: message,
      token: this.auth.token,
    });
  }
}
