import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ThemeService } from 'src/app/services/theme.service';
import * as Chart from 'chart.js';
import { Subscription } from 'rxjs';
import { BallotService } from '../../services/ballot.service';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import {
  Ballot,
  ElectionResult,
  PartyResult,
} from '../../interfaces/ballot.interface';

@Component({
  selector: 'app-modal-stats',
  templateUrl: './modal-stats.component.html',
  styleUrls: ['./modal-stats.component.scss'],
})
export class ModalStatsComponent implements AfterViewInit {
  @ViewChild('votesChart')
  public votesChartRef: ElementRef;
  public votesChartInstance: any;
  private ballotEvent: Subscription;

  private parties = [
    {
      name: 'Pan',
      color: '#06338E',
      avatar: 'assets/parties/pan.svg',
      votes: 0,
    },
    {
      name: 'Pan',
      color: '#B5261E',
      avatar: 'assets/parties/morena.svg',
      votes: 0,
    },
    {
      name: 'Pan',
      color: '#D23026',
      avatar: 'assets/parties/pri.svg',
      votes: 0,
    },
    {
      name: 'Pan',
      color: '#FFCB01',
      avatar: 'assets/parties/prd.svg',
      votes: 0,
    },
    {
      name: 'Pan',
      color: '#C82039',
      avatar: 'assets/parties/verde.svg',
      votes: 0,
    },
    {
      name: 'Pan',
      color: '#DA251D',
      avatar: 'assets/parties/pt.svg',
      votes: 0,
    },
    {
      name: 'Pan',
      color: '#FF8300',
      avatar: 'assets/parties/mc.svg',
      votes: 0,
    },
    {
      name: 'Pan',
      color: '#00BFA5',
      avatar: 'assets/parties/no.svg',
      votes: 0,
    },
    {
      name: 'Pan',
      color: '#00A4AC',
      avatar: 'assets/parties/alianza.svg',
      votes: 0,
    },
    {
      name: 'Pan',
      color: '#5A2A7C',
      avatar: 'assets/parties/pes.svg',
      votes: 0,
    },
    {
      name: 'Pan',
      color: '#2E3233',
      avatar: 'assets/parties/rsp.svg',
      votes: 0,
    },
    {
      name: 'Pan',
      color: '#EC62A0',
      avatar: 'assets/parties/fpm.svg',
      votes: 0,
    },
    {
      name: 'Pan',
      color: '#101011',
      avatar: 'assets/parties/no.svg',
      votes: 0,
    },
  ];

  constructor(
    private readonly themeService: ThemeService,
    private readonly toastController: ToastController,
    private readonly ballotService: BallotService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.ballotEvent = this.ballotService.ballots$
        .pipe(
          filter((data) => {
            return data != null;
          })
        )
        .subscribe((data: Ballot[]) => {
          let results: PartyResult[] = _.flatten(
            _.flatten(
              _.cloneDeep(data).map((item) => {
                return item.electionResults as ElectionResult[];
              })
            )
              .filter((item) => {
                return item.electionType == params['electionType'];
              })
              .map((item) => {
                return item.partyResults as PartyResult[];
              })
          );

          this.parties.forEach((party) => {
            let update = _.filter(results, (item) => {
              return (
                _.last(party.avatar.split('/')) ==
                _.last(item.party.avatar.split('/'))
              );
            });

            party.votes = 0;
            if (update.length != 0) {
              party.name = update[0].party.name;
              update.forEach((item) => {
                party.votes += item.votes;
              });
            }
          });

          if (this.votesChartInstance) this.handleChartUpdate();
        });
    });
  }

  private handleChartUpdate() {
    this.votesChartInstance.data.datasets[0].data = this.parties.map((item) => {
      return item.votes;
    });
    this.votesChartInstance.update();
  }

  ngAfterViewInit() {
    this.votesChartInstance = new Chart(this.votesChartRef.nativeElement, {
      type: 'bar',
      data: {
        labels: this.parties.map((item) => {
          return item.name;
        }),
        datasets: [
          {
            data: this.parties.map((item) => {
              return item.votes;
            }),
            backgroundColor: this.parties.map((item) => {
              return item.color;
            }),
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              beginAtZero: true,
              ticks: {
                display: false,
              },
            },
          ],
          yAxes: [{}],
        },
        plugins: {
          legend: false,
          title: {
            display: false,
          },
        },
      },
    });
  }

  public async onCancel(): Promise<void> {
    this.themeService.isModal$.next({
      name: 'maps',
      isOpen: false,
      returns: null,
      action: 'nothing',
    });

    const toast = await this.toastController.create({
      message: 'Haz salido del visualizado de resultados',
      color: 'success',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }
}
