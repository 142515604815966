import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ThemeService } from '../../services/theme.service';
import { User } from 'src/app/interfaces/user.interface';
import * as _ from 'lodash';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import {
  Ballot,
  ElectionResult,
  AVAILABLE_STATE,
  RECEIVED_STATE,
} from '../../interfaces/ballot.interface';
import { BallotOwnerService } from '../../services/ballot-owner.service';
import { filter } from 'rxjs/operators';
import { ChatSupporterService } from '../../services/chat-supporter.service';
import { Modal } from 'src/app/interfaces/modal.interface';
import { PartyResult } from '../../interfaces/ballot.interface';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

type ExtUser = User & { isMember: Boolean };

@Component({
  selector: 'app-modal-ballot',
  templateUrl: './modal-ballot.component.html',
  styleUrls: ['./modal-ballot.component.scss'],
})
export class ModalBallotComponent implements OnInit {
  public currentStep: number;
  public ballotOwners: User[];
  public chatSupporters: User[];
  public availableBallotOwners: ExtUser[];
  public availableChatSupporters: ExtUser[];
  public availableElectionResults: ElectionResult[];

  public ballotForm: FormGroup;
  private payload: Ballot;

  private ballotOwnerEvent: Subscription;
  private chatSupportersEvent: Subscription;
  private modalEvent: Subscription;

  @ViewChild('accordion', { static: false })
  public accordion: NgbAccordion;

  constructor(
    private readonly themeService: ThemeService,
    private readonly toastController: ToastController,
    private readonly ballotOwnerService: BallotOwnerService,
    private readonly chatSupporterService: ChatSupporterService
  ) {
    this.currentStep = 0;
    this.ballotOwners = [];
    this.availableBallotOwners = [];
    this.availableChatSupporters = [];
    this.availableElectionResults = [];
    this.chatSupporters = [];
    this.handleServerData();
  }

  private handleServerData(): void {
    this.ballotOwnerEvent = this.ballotOwnerService.ballotOwners$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data) => {
        data = _.cloneDeep(data);
        this.availableBallotOwners = data.map((user) => {
          return { ...user, isMember: false };
        });
      });

    this.chatSupportersEvent = this.chatSupporterService.chatSupporter$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data) => {
        data = _.cloneDeep(data);
        this.availableChatSupporters = data.map((chatSupporter) => {
          return { ...chatSupporter, isMember: false };
        });
      });

    this.modalEvent = this.themeService.isModal$
      .pipe(
        filter(({ isOpen }: Modal) => {
          return isOpen;
        })
      )
      .subscribe(({ payload }) => {
        this.payload = _.cloneDeep(payload);
      });
  }

  public async onCancel(): Promise<void> {
    this.themeService.isModal$.next({
      name: 'ballot',
      isOpen: false,
      returns: null,
      action: 'nothing',
    });

    const toast = await this.toastController.create({
      message: 'Saliste del editor, la información fue descartada',
      color: 'warning',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  public async onContinueError(): Promise<void> {
    const toast = await this.toastController.create({
      message: 'No haz completado la información correctamente',
      color: 'danger',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  public async onContinue(): Promise<void> {
    this.availableElectionResults.forEach((item) => {
      item.partyResults.forEach((subitem) => {
        subitem.votes = (
          this.ballotForm.controls.electionResults.get(item._id) as FormGroup
        ).get(subitem._id).value;
      });
    });

    this.themeService.isModal$.next({
      name: 'ballot',
      isOpen: false,
      returns: {
        _id: this.payload._id,
        ballotOwners: _.filter(this.availableBallotOwners, {
          isMember: true,
        }).map((user: any) => {
          return user._id;
        }) as any,
        chatSupporters: this.chatSupporters.map((user) => {
          return user._id;
        }) as any,
        state: {
          availableState:
            AVAILABLE_STATE[
              this.ballotForm.value.availableState
                ? 'AVAILABLE'
                : 'NOT-AVAILABLE'
            ],
          receivedState:
            RECEIVED_STATE[
              this.ballotForm.value.receivedState ? 'RECEIVED' : 'NOT-RECEIVED'
            ],
        },
        electionResults: this.availableElectionResults,
      },
      action: 'update',
    });
  }

  public onBallotOwner(data: any, isMember: boolean): void {
    this.availableBallotOwners = this.availableBallotOwners.map((user) => {
      return { ...user, ...{ isMember: false } };
    });
    this.availableBallotOwners.map((user) => {
      if (user._id == data._id) user.isMember = !isMember;
    });
  }

  public onChatSupporter(data: any, isMember: boolean): void {
    this.chatSupporters = [];
    this.availableChatSupporters.map((chatSupporter) => {
      if (chatSupporter._id == data._id) chatSupporter.isMember = !isMember;
      if (chatSupporter.isMember) this.chatSupporters.push(chatSupporter);
    });
  }

  private initForm(): void {
    this.ballotForm = new FormGroup({
      availableState: new FormControl(false),
      receivedState: new FormControl(false),
      electionResults: new FormGroup({}),
    });
  }

  ngAfterViewInit(): void {
    this.accordion.expand(`ngb-panel-${this.currentStep}`);
  }

  ngOnInit(): void {
    this.initForm();
    if (this.payload) {
      this.ballotForm.patchValue({
        availableState: this.payload.state.availableState == 1,
        receivedState: this.payload.state.receivedState == 1,
      });

      this.availableChatSupporters = this.availableChatSupporters.map(
        (user) => {
          if (_.find(this.payload.chatSupporters, { _id: user._id })) {
            user.isMember = true;
            this.chatSupporters.push(user);
          }
          return user;
        }
      );

      this.availableBallotOwners = this.availableBallotOwners.map((user) => {
        if (_.find(this.payload.ballotOwners, { _id: user._id })) {
          user.isMember = true;
          this.ballotOwners.push(user);
        }
        return user;
      });

      this.payload.electionResults.map((item) => {
        this.availableElectionResults.push(item);
        (this.ballotForm.get('electionResults') as FormGroup).addControl(
          item._id,
          new FormGroup(
            _.fromPairs(
              item.partyResults.map((subitem) => {
                return [
                  subitem._id,
                  new FormControl(isNaN(subitem?.votes) ? 0 : subitem?.votes),
                ];
              })
            )
          )
        );
      });
    }
  }

  ngOnDestroy(): void {
    this.modalEvent.unsubscribe();
    this.ballotOwnerEvent.unsubscribe();
    this.chatSupportersEvent.unsubscribe();
  }
}
