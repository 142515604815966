import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

import { ThemeService } from '../../services/theme.service';
import { Modal } from '../../interfaces/modal.interface';
import { Party } from '../../interfaces/party.interface';

import * as _ from 'lodash';

@Component({
  selector: 'app-modal-party',
  templateUrl: './modal-party.component.html',
  styleUrls: ['./modal-party.component.scss'],
})
export class ModalPartyComponent implements OnInit {
  public payload: Party;
  public partyForm: FormGroup;
  public file: File;
  private modalEvent: Subscription;

  @ViewChild('image', { static: true })
  public image?: ElementRef;

  constructor(
    private readonly themeService: ThemeService,
    private readonly toastController: ToastController
  ) {
    this.modalEvent = this.themeService.isModal$.subscribe(
      ({ payload }: Modal) => {
        this.payload = _.cloneDeep(payload);
      }
    );
  }

  public async onCancel(): Promise<void> {
    this.themeService.isModal$.next({
      name: 'party',
      action: 'nothing',
      isOpen: false,
      returns: null,
    });

    const toast = await this.toastController.create({
      message: 'Saliste del editor, la información fue descartada',
      color: 'warning',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  public async onContinueError(): Promise<void> {
    const toast = await this.toastController.create({
      message: 'No haz completado la información correctamente',
      color: 'danger',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  public async onContinue(): Promise<void> {
    const formPayload = this.partyForm.value;
    delete formPayload.confirmPassword;

    this.themeService.isModal$.next({
      name: 'party',
      isOpen: false,
      returns: this.payload
        ? { _id: this.payload._id, ...formPayload, file: this.file }
        : { ...formPayload, file: this.file },
      action: this.payload ? 'update' : 'create',
    });
  }

  public onFilesDropped(files: NgxFileDropEntry[]) {
    if (
      files &&
      files.length != 0 &&
      files[0].fileEntry.isFile &&
      files[0].fileEntry.name.endsWith('.svg')
    ) {
      const fileEntry = files[0].fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        let fileReader = new FileReader();
        fileReader.onload = () => {
          this.file = file;
          this.image.nativeElement.src = fileReader.result;
        };
        fileReader.readAsDataURL(file);
      });
    } else {
      this.toastController
        .create({
          message: 'No es un archivo valido',
          color: 'danger',
          position: 'top',
          mode: 'ios',
          duration: 6000,
        })
        .then((toast) => {
          toast.present();
        });
    }
  }

  private initForm(): void {
    this.partyForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.initForm();
    if (this.payload) this.partyForm.patchValue(this.payload);
  }

  ngOnDestroy(): void {
    this.modalEvent.unsubscribe();
  }
}
