import { Component, OnInit } from '@angular/core';
import { Chat, CHAT_TYPE } from 'src/app/interfaces/chat.interface';
import { Subscription } from 'rxjs';
import { ChatService } from '../../services/chat.service';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss'],
})
export class ChatsComponent implements OnInit {
  public chats: Chat[];
  public chatsEvent: Subscription;

  constructor(private readonly chatService: ChatService) {
    this.chats = [];
    this.chatsEvent = this.chatService.chats$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data) => {
        data = _.cloneDeep(data).filter((item) => {
          return (
            item.members.length >= 2 ||
            item.chatType == CHAT_TYPE['CHAT_SUPPORT_CHAT']
          );
        });
        this.chats = _.uniqBy([...this.chats, ...data], '_id');
        this.chats = _.intersectionBy(this.chats, data, '_id');

        for (let chat of this.chats) {
          let update = _.find(data, { _id: chat._id });
          if (update) _.merge(chat, _.omit(update, '_id'));
        }
      });
  }

  ngOnInit() {}
  ngOnDestroy() {
    this.chatsEvent.unsubscribe();
  }
}
