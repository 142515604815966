export const environment = {
  production: false,
  tracking: true,
  splashScreen: {
    show: false,
    panSplashDuration: 3000,
    cuadrosoftSplashDuration: 3000,
    splashFadeDuration: 300,
  },
  defaultRoute: 'home/stats',
  theme: {
    startNotificationPanel: false,
  },
  server: {
    base_url: 'http://localhost:3000',
    // base_url: 'http://18.222.37.194:3000',
    maps_key: 'AIzaSyDi8RCLH3I79clMJwd9oFRX5eE29to_cno',
  },
  navigation: [
    {
      icon: 'graph-up',
      path: 'home/stats',
      text: 'Estadísticas y Resultados',
    },
    {
      icon: 'archive',
      path: 'home/sections',
      text: 'Información de Secciónes',
    },
    {
      icon: 'file-earmark',
      path: 'home/polls',
      text: 'Información de Casillas',
    },
    {
      icon: 'award',
      path: 'home/ballots',
      text: 'Información de Actas',
    },
    {
      icon: 'clipboard',
      path: 'home/supporters',
      text: 'Información de Movilización',
    },
    {
      icon: 'briefcase-fill',
      path: 'home/parties',
      text: 'Registros de Partidos',
    },
    {
      icon: 'people-fill',
      path: 'home/voters',
      text: 'Registros de Electores',
    },
    {
      icon: 'people-fill',
      path: 'home/users',
      text: 'Registros de Usuarios',
    },
    // {
    //   icon: 'compass',
    //   path: 'home/maps',
    //   text: 'Mapa de ubicaciones',
    // },
    {
      icon: 'chat-left-fill',
      path: 'home/chat',
      text: 'Mensajería de Asistencia ',
    },
  ],
};
