import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages/pages.component';
import { StatsComponent } from './pages/stats/stats.component';
import { PollsComponent } from './pages/polls/polls.component';
import { SupportersComponent } from './pages/supporters/supporters.component';
import { BallotsComponent } from './pages/ballots/ballots.component';
import { VotersComponent } from './pages/voters/voters.component';
import { ChatsComponent } from './pages/chats/chats.component';
import { SectionsComponent } from './pages/sections/sections.component';
import { UsersComponent } from './pages/users/users.component';

import { ModalsComponent } from './modals/modals.component';
import { PartiesComponent } from './pages/parties/parties.component';
import { ModalPollComponent } from './modals/modal-poll/modal-poll.component';
import { ModalAdminComponent } from './modals/modal-admin/modal-admin.component';
import { ModalVoterComponent } from './modals/modal-voter/modal-voter.component';
import { ModalPartyComponent } from './modals/modal-party/modal-party.component';
import { ModalAccessComponent } from './modals/modal-access/modal-access.component';
import { ModalBallotComponent } from './modals/modal-ballot/modal-ballot.component';
import { ModalSectionComponent } from './modals/modal-section/modal-section.component';
import { ModalConfirmComponent } from './modals/modal-confirm/modal-confirm.component';
import { ModalPollOwnerComponent } from './modals/modal-poll-owner/modal-poll-owner.component';
import { ModalBallotOwnerComponent } from './modals/modal-ballot-owner/modal-ballot-owner.component';
import { ModalSupportListComponent } from './modals/modal-support-list/modal-support-list.component';
import { ModalChatSupporterComponent } from './modals/modal-chat-supporter/modal-chat-supporter.component';
import { ModalDetectedVotersComponent } from './modals/modal-detected-voters/modal-detected-voters.component';
import { ModalSupportListOwnerComponent } from './modals/modal-support-list-owner/modal-support-list-owner.component';
import { ChatComponent } from './pages/chats/chat/chat.component';
import { ModalMapsComponent } from './modals/modal-maps/modal-maps.component';
import { MapsComponent } from './pages/maps/maps.component';
import { ModalStatsComponent } from './modals/modal-stats/modal-stats.component';

const routes: Routes = [
  {
    path: 'home',
    component: PagesComponent,
    children: [
      { path: 'stats', component: StatsComponent },
      { path: 'sections', component: SectionsComponent },
      { path: 'polls', component: PollsComponent },
      { path: 'supporters', component: SupportersComponent },
      { path: 'ballots', component: BallotsComponent },
      { path: 'parties', component: PartiesComponent },
      { path: 'voters', component: VotersComponent },
      { path: 'users', component: UsersComponent },
      { path: 'maps', component: MapsComponent },
      {
        path: 'chat',
        component: ChatsComponent,
        children: [
          {
            path: '',
            component: ChatComponent,
          },
          {
            path: ':id',
            component: ChatComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'modal',
    outlet: 'modal',
    component: ModalsComponent,
    children: [
      {
        path: 'confirm',
        component: ModalConfirmComponent,
      },
      {
        path: 'access',
        component: ModalAccessComponent,
      },
      {
        path: 'section',
        component: ModalSectionComponent,
      },
      {
        path: 'admin',
        component: ModalAdminComponent,
      },
      {
        path: 'poll',
        component: ModalPollComponent,
      },
      {
        path: 'poll-owner',
        component: ModalPollOwnerComponent,
      },
      {
        path: 'ballot',
        component: ModalBallotComponent,
      },
      {
        path: 'ballot-owner',
        component: ModalBallotOwnerComponent,
      },
      {
        path: 'support-list',
        component: ModalSupportListComponent,
      },
      {
        path: 'support-list-owner',
        component: ModalSupportListOwnerComponent,
      },
      {
        path: 'chat-supporter',
        component: ModalChatSupporterComponent,
      },
      {
        path: 'voter',
        component: ModalVoterComponent,
      },
      {
        path: 'detected-voters',
        component: ModalDetectedVotersComponent,
      },
      {
        path: 'party',
        component: ModalPartyComponent,
      },
      {
        path: 'maps',
        component: ModalMapsComponent,
      },
      {
        path: 'stats/:electionType',
        component: ModalStatsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
