import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { UserService } from 'src/app/services/user.service';
import { ThemeService } from 'src/app/services/theme.service';
import { NETWORK_STATUS, ROLES, User } from 'src/app/interfaces/user.interface';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnDestroy {
  public users: User[];
  public onlineUsers: number;
  private userSubscription: Subscription;

  constructor(
    private readonly userService: UserService,
    private readonly themeService: ThemeService
  ) {
    this.users = [];
    this.onlineUsers = 0;

    this.userSubscription = this.userService.users$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data: User[]) => {
        data = _.cloneDeep(data);
        this.users = _.uniqBy([...this.users, ...data], '_id');
        this.users = _.intersectionBy(this.users, data, '_id');
        for (let user of this.users) {
          let update = _.find(data, { _id: user._id });
          if (update) _.merge(user, _.omit(update, '_id'));
        }

        this.onlineUsers = this.users.filter((item) => {
          return item.status.networkStatus.status == NETWORK_STATUS['ONLINE'];
        }).length;
      });
  }

  public onAdmin(admin?: User): void {
    this.themeService.isModal$.next({
      name: 'admin',
      isOpen: true,
      payload: admin,
      action: admin ? 'update' : 'create',
    });
  }

  public onPollOwner(pollOwner?: User): void {
    this.themeService.isModal$.next({
      name: 'poll-owner',
      isOpen: true,
      payload: pollOwner,
      action: pollOwner ? 'update' : 'create',
    });
  }

  public onBallotOwner(ballotOwner?: User): void {
    this.themeService.isModal$.next({
      name: 'ballot-owner',
      isOpen: true,
      payload: ballotOwner,
      action: ballotOwner ? 'update' : 'create',
    });
  }

  public onSupportListOwner(supportListOwner?: User): void {
    this.themeService.isModal$.next({
      name: 'support-list-owner',
      isOpen: true,
      payload: supportListOwner,
      action: supportListOwner ? 'update' : 'create',
    });
  }

  public onChatSupporter(chatOwner?: User): void {
    this.themeService.isModal$.next({
      name: 'chat-supporter',
      isOpen: true,
      payload: chatOwner,
      action: chatOwner ? 'update' : 'create',
    });
  }

  public onDelete(user: User) {
    this.themeService.isModal$.next({
      name: 'confirm',
      isOpen: true,
      payload: `delete-${ROLES[user.role].split('_').join('-').toLowerCase()}/${
        user._id
      }`,
      action: 'delete',
    });
  }

  public onEdit(user: User) {
    switch (Number(user.role)) {
      case ROLES['ADMIN']:
        this.onAdmin(user);
        break;
      case ROLES['POLL_OWNER']:
        this.onPollOwner(user);
        break;
      case ROLES['BALLOT_OWNER']:
        this.onBallotOwner(user);
        break;
      case ROLES['SUPPORT_LIST_OWNER']:
        this.onSupportListOwner(user);
        break;
      case ROLES['CHAT_SUPPORTER']:
        this.onChatSupporter(user);
        break;
    }
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }
}
