import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../interfaces/user.interface';

@Component({
  selector: 'app-voter-item',
  templateUrl: './voter-item.component.html',
  styleUrls: ['./voter-item.component.scss'],
})
export class VoterItemComponent implements OnInit {
  @Input('voter')
  public voter: User;

  constructor() {}

  ngOnInit() {}
}
