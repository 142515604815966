import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input('user')
  public user: any;

  @Input('showLabel')
  public showLabel: boolean;

  constructor() {
    this.showLabel = false;
  }

  ngOnInit() {}
}
