import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnInit {
  @Input('title')
  public title: string;

  @Input('subtitle')
  public subtitle: string;

  @Input('moment')
  public moment: string;

  @Input('status')
  public status: string;

  constructor() {}

  ngOnInit() {}
}
