import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import * as Chart from 'chart.js';

import Swiper, { SwiperOptions } from 'swiper';

import {
  DETECTION_STATUS,
  User,
  VISIT_STATUS,
  VOTING_STATUS,
} from '../../interfaces/user.interface';
import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { VoterService } from '../../services/voter.service';
import { WORKING_STATUS, ROLES } from '../../interfaces/user.interface';

import * as _ from 'lodash';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class StatsComponent implements OnInit {
  private voters: User[];
  private users: User[];
  public votedDetectedVoters: User[];
  public votedUndetectedVoters: User[];
  public notificationStackDetectedVoters: { voter: User; timestamp: number }[];
  public notificationStackUndetectedVoters: {
    voter: User;
    timestamp: number;
  }[];

  private userEvent: Subscription;
  private voterEvent: Subscription;

  public swiperConfig: SwiperOptions = {
    spaceBetween: 10,
    slidesPerView: 'auto',
  };

  public isNewData;
  public votesChartInstance: any;
  public voterChartInstance: any;
  public supporterChartInstance: any;
  public pollOwnerChartInstance: any;

  @ViewChild('votesChart')
  public votesChartRef: ElementRef;

  constructor(
    private readonly userService: UserService,
    private readonly voterService: VoterService,
    private readonly themeService: ThemeService
  ) {
    this.isNewData = true;
    this.voters = [];
    this.users = [];
    this.votedDetectedVoters = [];
    this.votedUndetectedVoters = [];
    this.notificationStackDetectedVoters = [];
    this.notificationStackUndetectedVoters = [];
  }

  public onRepresentativeStats() {
    this.themeService.isModal$.next({
      name: 'stats/0',
      isOpen: true,
      payload: null,
      action: 'nothing',
    });
  }

  public onMayorStats() {
    this.themeService.isModal$.next({
      name: 'stats/1',
      isOpen: true,
      payload: null,
      action: 'nothing',
    });
  }

  private updateUserCharts(): void {
    if (this.supporterChartInstance && this.pollOwnerChartInstance) {
      let users: User[] = this.users;
      let supportListOwners = users.filter((item) => {
        return item.role == ROLES['SUPPORT_LIST_OWNER'];
      });
      let pollOwners = users.filter((item) => {
        return item.role == ROLES['POLL_OWNER'];
      });

      //for supporterChartInstance
      let waitingSupporters: number; //en sistema
      let workingSupporters: number; //presentes
      let finishedSupporters: number; //finalizados
      let disconnectedSupporters: number; //sin iniciar

      waitingSupporters = supportListOwners.filter((item) => {
        return item.status.workingStatus.status == WORKING_STATUS['WAITING'];
      }).length;
      workingSupporters = supportListOwners.filter((item) => {
        return item.status.workingStatus.status == WORKING_STATUS['WORKING'];
      }).length;
      finishedSupporters = supportListOwners.filter((item) => {
        return item.status.workingStatus.status == WORKING_STATUS['FINISHED'];
      }).length;

      disconnectedSupporters =
        supportListOwners.length -
        waitingSupporters -
        workingSupporters -
        finishedSupporters;

      this.supporterChartInstance.data.datasets[0].data = [
        waitingSupporters,
        workingSupporters,
        finishedSupporters,
        disconnectedSupporters,
      ];

      this.supporterChartInstance.update();

      //for pollOwnerChartInstance
      let waitingPollOwners: number; //en sistema
      let workingPollOwners: number; //presentes
      let finishedPollOwners: number; //finalizados
      let disconnectedPollOwners: number; //sin iniciar

      waitingPollOwners = pollOwners.filter((item) => {
        return item.status.workingStatus.status == WORKING_STATUS['WAITING'];
      }).length;
      workingPollOwners = pollOwners.filter((item) => {
        return item.status.workingStatus.status == WORKING_STATUS['WORKING'];
      }).length;
      finishedPollOwners = pollOwners.filter((item) => {
        return item.status.workingStatus.status == WORKING_STATUS['FINISHED'];
      }).length;

      disconnectedPollOwners =
        pollOwners.length -
        waitingPollOwners -
        workingPollOwners -
        finishedPollOwners;

      this.pollOwnerChartInstance.data.datasets[0].data = [
        waitingPollOwners,
        workingPollOwners,
        finishedPollOwners,
        disconnectedPollOwners,
      ];

      this.pollOwnerChartInstance.update();
    }
  }

  private updateVersusChart(): void {
    if (this.votesChartInstance) {
      let voters = this.voters;
      //detected
      let detectedVoted: User[] = voters.filter((item) => {
        return (
          item.status.detectionStatus.status == DETECTION_STATUS['DETECTED'] &&
          item.status.votingStatus.status == VOTING_STATUS['VOTED']
        );
      });
      let undetectedVoted: User[] = voters.filter((item) => {
        return (
          item.status.detectionStatus.status ==
            DETECTION_STATUS['UNDETECTED'] &&
          item.status.votingStatus.status == VOTING_STATUS['VOTED']
        );
      });

      this.votesChartInstance.data.datasets[0].data = [
        detectedVoted.length,
        undetectedVoted.length,
      ];
      this.votesChartInstance.update();
    }
  }

  private updateVoterCharts(): void {
    if (this.voterChartInstance) {
      let voters = this.voters;
      //detected
      let detectedVoters: User[] = voters.filter((item) => {
        return (
          item.status.detectionStatus.status == DETECTION_STATUS['DETECTED']
        );
      });

      //for voterChartInstance
      let notVoted: number;
      let voted: number;

      this.votedDetectedVoters = detectedVoters.filter((item) => {
        return item.status.votingStatus.status == VOTING_STATUS['VOTED'];
      });

      this.votedUndetectedVoters = voters.filter((item) => {
        return (
          item.status.detectionStatus.status ==
            DETECTION_STATUS['UNDETECTED'] &&
          item.status.votingStatus.status == VOTING_STATUS['VOTED']
        );
      });

      voted = this.votedDetectedVoters.length;
      notVoted = detectedVoters.length - voted;
      this.voterChartInstance.data.datasets[0].data = [voted, notVoted];

      this.voterChartInstance.update();
    }
  }

  public onSwiper(swiper: Swiper): void {
    this.voterChartInstance = new Chart(
      swiper.slides[0].getElementsByTagName('canvas')[0],
      {
        type: 'bar',
        data: {
          labels: ['Votado', 'No votado'],
          datasets: [
            {
              data: [0, 0],
              backgroundColor: ['#007BFF', '#6C757D'],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                ticks: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          plugins: {
            legend: false,
            title: {
              display: false,
            },
          },
        },
      }
    );

    this.supporterChartInstance = new Chart(
      swiper.slides[1].getElementsByTagName('canvas')[0],
      {
        type: 'bar',
        data: {
          labels: ['En espera', 'En sistema', 'Finalizados', 'Sin iniciar'],
          datasets: [
            {
              data: [0, 0, 0, 0],
              backgroundColor: ['#007BFF', '#28A745', '#17A2B8', '#DC3545'],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                ticks: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          plugins: {
            legend: false,
            title: {
              display: false,
            },
          },
        },
      }
    );

    this.pollOwnerChartInstance = new Chart(
      swiper.slides[2].getElementsByTagName('canvas')[0],
      {
        type: 'bar',
        data: {
          labels: ['En espera', 'En sistema', 'Finalizados', 'Sin iniciar'],
          datasets: [
            {
              data: [0, 0, 0, 0],
              backgroundColor: ['#007BFF', '#28A745', '#17A2B8', '#DC3545'],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                ticks: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          plugins: {
            legend: false,
            title: {
              display: false,
            },
          },
        },
      }
    );

    this.updateVoterCharts();
    this.updateUserCharts();
  }

  ngAfterViewInit() {
    this.votesChartInstance = new Chart(this.votesChartRef.nativeElement, {
      type: 'horizontalBar',
      data: {
        labels: ['Votado', 'No votado'],
        datasets: [
          {
            data: [0, 0],
            backgroundColor: ['#007BFF', '#6C757D'],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                display: false,
              },
            },
          ],
        },
        plugins: {
          legend: false,
          title: {
            display: false,
          },
        },
      },
    });

    this.userEvent = this.userService.users$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data) => {
        this.users = _.cloneDeep(data);
        this.updateUserCharts();
      });

    this.voterEvent = this.voterService.voters$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data) => {
        this.voters = _.cloneDeep(data);
        this.updateVoterCharts();
        this.updateVersusChart();
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.userEvent.unsubscribe();
    this.voterEvent.unsubscribe();
  }
}
