import { Component, OnInit } from '@angular/core';
import { Party } from '../../interfaces/party.interface';
import { ThemeService } from 'src/app/services/theme.service';
import { Subscription } from 'rxjs';
import { VoterService } from '../../services/voter.service';
import { PartyService } from '../../services/party.service';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-parties',
  templateUrl: './parties.component.html',
  styleUrls: ['./parties.component.scss'],
})
export class PartiesComponent implements OnInit {
  public parties: Party[];
  private partiesSubscription: Subscription;

  constructor(
    private readonly themeService: ThemeService,
    private readonly partyService: PartyService
  ) {
    this.parties = [];
    this.handleDataService();
  }

  private handleDataService(): void {
    this.partiesSubscription = this.partyService.parties$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data: Party[]) => {
        data = _.cloneDeep(data);
        this.parties = _.uniqBy([...this.parties, ...data], '_id');
        this.parties = _.intersectionBy(this.parties, data, '_id');
        for (let party of this.parties) {
          let update = _.find(data, { _id: party._id });
          if (update) _.merge(party, _.omit(update, '_id'));
        }
      });
  }

  onParty(party?: Party): void {
    this.themeService.isModal$.next({
      name: 'party',
      isOpen: true,
      payload: party,
      action: party ? 'update' : 'create',
    });
  }

  onDelete(party?: Party): void {
    this.themeService.isModal$.next({
      name: 'confirm',
      isOpen: true,
      payload: `delete-party/${party._id}`,
      action: 'delete',
    });
  }

  ngOnInit() {}
  ngOnDestroy() {
    this.partiesSubscription.unsubscribe();
  }
}
