import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ThemeService } from '../../services/theme.service';
import { ToastController } from '@ionic/angular';
import { Modal } from '../../interfaces/modal.interface';
import * as _ from 'lodash';
import { User } from 'src/app/interfaces/user.interface';

@Component({
  selector: 'app-modal-maps',
  templateUrl: './modal-maps.component.html',
  styleUrls: ['./modal-maps.component.scss'],
})
export class ModalMapsComponent implements OnInit {
  private map: google.maps.Map;
  private heatmap: google.maps.visualization.HeatmapLayer;

  public payload: User;
  private modalEvent: Subscription;

  constructor(
    private readonly themeService: ThemeService,
    private readonly toastController: ToastController
  ) {
    this.map = null;
    this.heatmap = null;
    this.modalEvent = this.themeService.isModal$.subscribe(
      ({ payload }: Modal) => {
        this.payload = _.cloneDeep(payload);
      }
    );
  }

  public onMapLoad(mapInstance: google.maps.Map) {
    this.map = mapInstance;

    this.map.setOptions({
      styles: [
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
      ],
    });

    if (this.payload) {
      const coords: google.maps.LatLng[] = this.payload.geolocationPositions.map(
        (item) => {
          return new google.maps.LatLng(
            item.coords.latitude,
            item.coords.longitude
          );
        }
      );

      this.map.setCenter(_.last(coords));
      this.map.setZoom(15);

      this.heatmap = new google.maps.visualization.HeatmapLayer({
        map: this.map,
        data: coords,
      });

      this.heatmap.setMap(this.map);
    }
  }

  public async onCancel(): Promise<void> {
    this.themeService.isModal$.next({
      name: 'maps',
      isOpen: false,
      returns: null,
      action: 'nothing',
    });

    const toast = await this.toastController.create({
      message: 'Haz salido del visualizado de movimientos',
      color: 'success',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  ngOnInit() {}
  ngOnDestroy() {
    this.modalEvent.unsubscribe();
  }
}
