import { FormControl, ValidationErrors } from '@angular/forms';

export class PasswordValidators {
  static async passwordMatchValidator(
    control: FormControl
  ): Promise<ValidationErrors | null> {
    let controlBind = this as any;
    let user = controlBind.userForm;

    return await new Promise((resolve) => {
      control.value === user.controls.password.value
        ? resolve(null)
        : resolve({
            mismatch: {
              valid: false,
            },
          });
    });
  }
}
