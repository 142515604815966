import { Component, OnDestroy, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { AuthService } from '../../services/auth.service';
import { Auth } from 'src/app/interfaces/user.interface';
import { User, NETWORK_STATUS } from '../../interfaces/user.interface';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  public user: User;
  private isNotification: boolean;
  private authSubscription: Subscription;
  private notificationSubscription: Subscription;

  constructor(
    private readonly themeService: ThemeService,
    private readonly authService: AuthService
  ) {
    this.notificationSubscription = this.themeService.isNotification$
      .pipe(distinctUntilChanged())
      .subscribe((value: boolean) => {
        this.isNotification = value;
      });
    this.authSubscription = this.authService.auth$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((value: Auth) => {
        this.user = value;
        this.user.status.networkStatus.status = NETWORK_STATUS['ONLINE'];
      });
  }

  public onNotificationToggle(): void {
    this.themeService.isNotification$.next(!this.isNotification);
  }

  public onLogout() {
    this.authService.auth$.next(null);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.notificationSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
  }
}
