import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Chat } from '../../../interfaces/chat.interface';
import { ChatService } from '../../../services/chat.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { User } from 'src/app/interfaces/user.interface';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  private colors = ['white', 'warning', 'info', 'secondary', 'light', 'dark'];

  @ViewChild('messages')
  private messages?: ElementRef;
  private id: string;
  public chat: Chat;
  private chatEvent: Subscription;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly chatService: ChatService
  ) {
    this.handleServerData();
  }
  async handleServerData(): Promise<void> {
    this.activatedRoute.params.subscribe(({ id }) => {
      this.id = id;
    });

    this.chatEvent = this.chatService.chats$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data) => {
        this.chat = _.cloneDeep(data).find((chat) => {
          let index = 0;
          if (chat._id == this.id) {
            if (this.messages)
              this.messages.nativeElement.scrollTop = this.messages.nativeElement.scrollHeight;

            chat.members.map((item: User & { color: string }) => {
              item.color = this.colors[index];
              index++;
              return item;
            });

            return true;
          }
          return false;
        });
      });
  }

  ngOnInit() {}
  ngAfterViewInit() {
    if (this.messages)
      this.messages.nativeElement.scrollTop = this.messages.nativeElement.scrollHeight;
  }
  ngOnDestroy() {
    this.chatEvent.unsubscribe();
  }
}
