import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input('icon')
  public icon: string;

  @Input('iconPath')
  public iconPath: string;

  @Input('color')
  public color: string;

  @Input('size')
  public size: string;

  constructor() {}

  ngOnInit() {}
}
