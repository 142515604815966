import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { ThemeService } from 'src/app/services/theme.service';
import { PollOwnerService } from 'src/app/services/poll-owner.service';
import { ChatSupporterService } from 'src/app/services/chat-supporter.service';
import { SectionService } from 'src/app/services/section.service';
import { VoterService } from 'src/app/services/voter.service';
import { User } from 'src/app/interfaces/user.interface';
import { Option } from 'src/app/interfaces/option.interface';
import { Modal } from 'src/app/interfaces/modal.interface';
import { filter } from 'rxjs/operators';

import * as _ from 'lodash';

type ExtUser = User & { isMember: Boolean };

@Component({
  selector: 'app-modal-poll',
  templateUrl: './modal-poll.component.html',
  styleUrls: ['./modal-poll.component.scss'],
})
export class ModalPollComponent implements OnInit, AfterViewInit {
  public loadedVoters: ExtUser[];
  private voterChunks: ExtUser[][];
  public currentChunk: number;

  public availableSections: Option[];
  public availablePollOwners: ExtUser[];
  public availableChatSupporters: ExtUser[];
  public availableVoters: ExtUser[];
  public pollOwners: User[];
  public chatSupporters: User[];
  public voters: User[];

  public currentStep: number;
  public payload: any;
  public pollForm: FormGroup;

  private modalEvent: Subscription;
  private sectionsEvent: Subscription;
  private pollOwnersEvent: Subscription;
  private chatSupportersEvent: Subscription;
  private votersEvent: Subscription;

  @ViewChild('accordion', { static: false })
  public accordion: NgbAccordion;

  constructor(
    private readonly themeService: ThemeService,
    private readonly toastController: ToastController,
    private readonly sectionService: SectionService,
    private readonly pollOwnerService: PollOwnerService,
    private readonly chatSupporterService: ChatSupporterService,
    private readonly voterService: VoterService
  ) {
    this.currentChunk = 0;
    this.currentStep = 0;
    this.loadedVoters = [];
    this.voterChunks = [];
    this.availableSections = [];
    this.availablePollOwners = [];
    this.availableChatSupporters = [];
    this.availableVoters = [];
    this.pollOwners = [];
    this.chatSupporters = [];
    this.voters = [];
    this.handleServerData();
  }

  private handleServerData(): void {
    this.sectionsEvent = this.sectionService.sections$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data) => {
        data = _.cloneDeep(data);
        this.availableSections = data.map((section) => {
          return { value: section._id, title: section.name };
        });
      });
    this.pollOwnersEvent = this.pollOwnerService.pollOwners$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data) => {
        data = _.cloneDeep(data);
        this.availablePollOwners = data.map((pollOwner) => {
          return { ...pollOwner, ...{ isMember: false, isBackup: true } };
        });
      });
    this.chatSupportersEvent = this.chatSupporterService.chatSupporter$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data) => {
        data = _.cloneDeep(data);
        this.availableChatSupporters = data.map((chatSupporter) => {
          return { ...chatSupporter, isMember: false };
        });
      });

    this.votersEvent = this.voterService.voters$.subscribe((data) => {
      this.availableVoters = _.sortBy(
        _.cloneDeep(data).map((item) => {
          const title = Number(item.title);
          item.title = title ? (title as any) : 0;
          return { ...item, isMember: false };
        }),
        'title'
      );
      this.voterChunks = _.chunk(this.availableVoters, 100);
      this.loadedVoters = this.replaceVoters(
        this.loadedVoters,
        _.flatten(_.take(this.voterChunks, this.currentChunk + 1))
      );
    });

    this.modalEvent = this.themeService.isModal$.subscribe(
      ({ payload }: Modal) => {
        this.payload = _.cloneDeep(payload);
      }
    );
  }

  private replaceVoters(source: ExtUser[], update: ExtUser[]): ExtUser[] {
    source = _.uniqBy([...source, ...update], '_id');
    source = _.intersectionBy(source, update, '_id');
    for (let voter of source) {
      let updateItem = _.find(update, { _id: voter._id });
      if (updateItem) _.merge(voter, _.omit(updateItem, '_id'));
    }
    return source;
  }

  public onLoadData(): void {
    if (this.voterChunks) {
      this.loadedVoters.push(...this.voterChunks[this.currentChunk]);
      this.currentChunk++;
    }
  }

  public validatePollOwners(): boolean {
    return this.pollOwners.some((user) => {
      return user.isBackup == false;
    });
  }

  public async onCancel(): Promise<void> {
    this.themeService.isModal$.next({
      name: 'poll',
      isOpen: false,
      returns: null,
      action: 'nothing',
    });

    const toast = await this.toastController.create({
      message: 'Saliste del editor, la información fue descartada',
      color: 'warning',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  public async onContinueError(): Promise<void> {
    const toast = await this.toastController.create({
      message: 'No haz completado la información correctamente',
      color: 'danger',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  public async onContinue(): Promise<void> {
    if (this.currentStep <= 3) {
      this.currentStep++;
      this.accordion.expand(`ngb-panel-${this.currentStep}`);
    } else {
      let formPayload = this.pollForm.value;

      formPayload.pollOwners = this.pollOwners.map((data) => {
        return data._id;
      });

      formPayload.chatSupporters = this.chatSupporters.map((data) => {
        return data._id;
      });

      formPayload.voters = this.voters.map((data) => {
        return data._id;
      });

      this.themeService.isModal$.next({
        name: 'poll',
        isOpen: false,
        returns: this.payload
          ? { _id: this.payload._id, ...formPayload }
          : formPayload,
        action: this.payload ? 'update' : 'create',
      });
    }
  }

  public onPollOwnerBackup(data: any): void {
    this.pollOwners.map((pollOwner) => {
      if (pollOwner._id == data._id) pollOwner.isBackup = false;
      else pollOwner.isBackup = true;
    });
  }

  public onPollOwner(data: any, isMember: boolean): void {
    this.pollOwners = [];
    this.availablePollOwners.map((pollOwner) => {
      if (pollOwner._id == data._id) pollOwner.isMember = !isMember;
      if (pollOwner.isMember) this.pollOwners.push(pollOwner);
    });
  }

  public onChatSupporter(data: any, isMember: boolean): void {
    this.chatSupporters = [];
    this.availableChatSupporters.map((chatSupporter) => {
      if (chatSupporter._id == data._id) chatSupporter.isMember = !isMember;
      if (chatSupporter.isMember) this.chatSupporters.push(chatSupporter);
    });
  }

  public onVoter(data: any, isMember: boolean): void {
    this.voters = [];
    this.availableVoters.map((voter) => {
      if (voter._id == data._id) voter.isMember = !isMember;
      if (voter.isMember) this.voters.push(voter);
    });
  }

  private initForm(): void {
    this.pollForm = new FormGroup({
      section: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      address: new FormGroup({
        county: new FormControl(''),
        city: new FormControl(''),
        streetNumber: new FormControl(''),
        streetName: new FormControl(''),
        apartmentName: new FormControl(''),
        zip: new FormControl(''),
      }),
    });
  }

  ngOnInit(): void {
    this.initForm();
    if (this.payload) {
      this.payload.section = this.payload.section._id;
      this.pollForm.patchValue(this.payload);

      this.availablePollOwners = this.availablePollOwners.map((user) => {
        if (_.find(this.payload.pollOwners, { _id: user._id })) {
          user.isMember = true;
          this.pollOwners.push(user);
        }
        return user;
      });

      this.availableChatSupporters = this.availableChatSupporters.map(
        (user) => {
          if (_.find(this.payload.chatSupporters, { _id: user._id })) {
            user.isMember = true;
            this.chatSupporters.push(user);
          }
          return user;
        }
      );

      this.availableVoters = this.availableVoters.map((user) => {
        if (_.find(this.payload.voters, { _id: user._id })) {
          user.isMember = true;
          this.voters.push(user);
        }
        return user;
      });

      if (this.pollOwners.length != 0) {
        this.pollOwners[0].isBackup = false;
        this.currentStep = 2;
        if (this.chatSupporters.length != 0) {
          this.currentStep = 3;
          if (this.voters.length != 0) this.currentStep = 4;
        }
      }
    }
  }

  ngAfterViewInit(): void {
    this.accordion.expand(`ngb-panel-${this.currentStep}`);
  }

  ngOnDestroy(): void {
    this.modalEvent.unsubscribe();
    this.sectionsEvent.unsubscribe();
    this.pollOwnersEvent.unsubscribe();
    this.chatSupportersEvent.unsubscribe();
    this.votersEvent.unsubscribe();
  }
}
