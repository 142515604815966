import { Component, OnInit, Input } from '@angular/core';
import { Party } from '../../../interfaces/party.interface';

@Component({
  selector: 'app-party-item',
  templateUrl: './party-item.component.html',
  styleUrls: ['./party-item.component.scss'],
})
export class PartyItemComponent implements OnInit {
  @Input('party')
  public party: Party;

  constructor() {}

  ngOnInit() {}
}
