import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  public currentUrl: string;
  public menu: { icon: string; path: string; text: string }[];

  constructor(private readonly router: Router) {
    this.menu = environment.navigation;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd)
        this.currentUrl = this.router.url.slice(1);
    });
  }

  public onNavigate(route) {
    this.router.navigate([route]);
  }

  ngOnInit() {}
}
