import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';

import { SocketNameSpace } from '../socket.namespace';
import { Response } from '../interfaces/response.interface';
import { Access } from '../interfaces/access.interface';
import { User } from '../interfaces/user.interface';
import { ThemeService } from './theme.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccessService {
  public access$: BehaviorSubject<Access[]>;
  private socket: Socket;

  constructor(
    private readonly toastController: ToastController,
    private readonly themeService: ThemeService
  ) {
    this.access$ = new BehaviorSubject(null);

    this.socket = new SocketNameSpace({
      url: `${environment.server.base_url}/access`,
      options: {},
    });
    this.observeServerData();
    this.observePrivateResponses();
    this.observeModals();
  }

  private observePrivateResponses(): void {
    this.socket
      .fromEvent('access-client:createOne')
      .subscribe(async ({ data }: Response<Access>) => {
        const toast = await this.toastController.create({
          message: `Hola ${data.masters[0].firstName}, espera mientras se genera tu acceso`,
          color: 'success',
          position: 'top',
          mode: 'ios',
          duration: 6000,
        });
        toast.present();
      });
  }

  private observeServerData(): void {
    this.socket.emit('access-server:findAll');
    this.socket
      .fromEvent('access-client:findAll')
      .subscribe(({ data }: Response<Access[]>) => {
        this.access$.next(data);
      });
  }

  private observeModals() {
    this.themeService.isModal$
      .pipe(
        filter(
          (modal) =>
            modal.name == 'access' &&
            modal.isOpen == false &&
            modal.returns != null
        )
      )
      .subscribe((modal) => {
        this.createOne(modal.returns);
      });
  }

  private createOne(user: User & { workday: string }): void {
    this.socket.emit('access-server:createOne', {
      ...user,
      accessWorkday: user.workday,
      accessName: 'Distrito XV',
    });
  }
}
