import { Access } from './access.interface';
import { Ballot } from './ballot.interface';
import { Poll } from './poll.interface';
import { SupportList } from './supportList.interface';
import { User } from './user.interface';

export enum CHAT_TYPE {
  'POLL_CHAT',
  'BALLOT_CHAT',
  'CHAT_SUPPORT_CHAT',
  'SUPPORT_LIST_CHAT',
}

export interface ChatMessage {
  _id?: string;
  sender: User & string;
  canSee: User[] & string[];
  seenBy: User[] & string[];
  message: null & string;
  timestamp: number;
}

export interface Chat {
  _id?: string;
  chatType: CHAT_TYPE;
  poll: Poll & string;
  ballot: Ballot & string;
  supportList: SupportList & string;
  members: User[] & string[];
  messages: ChatMessage[] & string[];
  access: Access[] & string[];
  updatedAt?: Date;
}
