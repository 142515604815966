import { Party } from './party.interface';
import { Poll } from './poll.interface';
import { Section } from './section.interface';
import { User } from './user.interface';
import { Access } from './access.interface';

export enum RECEIVED_STATE {
  'NOT-RECEIVED',
  'RECEIVED',
}

export enum AVAILABLE_STATE {
  'NOT-AVAILABLE',
  'AVAILABLE',
}

export enum ELECTION_TYPE {
  'LOCAL_REPRESENTATIVE',
  'MAYOR',
}

export interface BallotState {
  receivedState: RECEIVED_STATE;
  availableState: AVAILABLE_STATE;
}

export interface PartyResult {
  _id?: string;
  party: Party & string;
  votes: number;
}

export interface ElectionResult {
  _id?: string;
  electionType: ELECTION_TYPE;
  partyResults: PartyResult[] & string[];
}

export interface Ballot {
  _id?: string;
  section: Section;
  poll: Poll;
  ballotOwners: User[] & string[];
  chatSupporters: User[] & string[];
  state: BallotState;
  electionResults: ElectionResult[] & string[];
  access: Access[] & string[];
}
