import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { SocketNameSpace } from '../socket.namespace';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';
import { Section } from '../interfaces/section.interface';
import { Response } from '../interfaces/response.interface';
import { Auth } from '../interfaces/user.interface';
import { AuthService } from './auth.service';
import { ThemeService } from './theme.service';
import { filter } from 'rxjs/operators';
import { Modal } from '../interfaces/modal.interface';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SectionService {
  public sections$: BehaviorSubject<Section[]>;
  private socket: Socket;
  private auth: Auth;

  constructor(
    private readonly authService: AuthService,
    private readonly themeService: ThemeService,
    private readonly toastController: ToastController
  ) {
    this.sections$ = new BehaviorSubject(null);
    this.socket = new SocketNameSpace({
      url: `${environment.server.base_url}/section`,
      options: {},
    });
    this.observeServerData();
    this.observePrivateResponses();
    this.observeModals();
  }

  private observeServerData(): void {
    this.authService.auth$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data: Auth) => {
        this.auth = data;
        this.socket.emit('section-server:findAll', { token: this.auth.token });
        this.socket
          .fromEvent('section-client:findAll')
          .subscribe(({ data }: Response<Section[]>) => {
            this.sections$.next(data);
          });
      });
  }

  private observePrivateResponses(): void {
    this.socket
      .fromEvent('section-client:createOne')
      .subscribe(async ({ data }: Response<Section>) => {
        const toast = await this.toastController.create({
          message: `La sección ${data.name} se ha creado, todos los sistemas han sido notificados`,
          color: 'success',
          position: 'top',
          mode: 'ios',
          duration: 6000,
        });
        toast.present();
      });
    this.socket.fromEvent('section-client:deleteOne').subscribe(async () => {
      const toast = await this.toastController.create({
        message: `La sección se ha eliminado, todos los sistemas han sido notificados`,
        color: 'success',
        position: 'top',
        mode: 'ios',
        duration: 6000,
      });
      toast.present();
    });
  }

  private observeModals(): void {
    this.themeService.isModal$
      .pipe(
        filter(
          (modal) =>
            modal.name == 'section' &&
            modal.isOpen == false &&
            modal.returns != null
        )
      )
      .subscribe(({ action, returns }) => {
        if (action == 'create') this.createOne(returns);
        else if (action == 'update') this.updateOne(returns);
      });
    this.themeService.isModal$
      .pipe(
        filter(
          (modal) =>
            modal.name == 'confirm' &&
            modal.isOpen == false &&
            modal.action == 'delete' &&
            modal.returns != null &&
            modal.returns.startsWith('delete-section/')
        )
      )
      .subscribe(({ returns }: Modal) => {
        this.deleteOne(_.last(returns.split('/')));
      });
  }

  private createOne(section: Section): void {
    section.access = [];
    for (let access of this.auth.access) section.access.push(access);
    this.socket.emit('section-server:createOne', {
      ...section,
      token: this.auth.token,
    });
  }

  private updateOne(section: Section) {
    this.socket.emit('section-server:updateOne', {
      ...section,
      token: this.auth.token,
    });
  }

  private deleteOne(id: string): void {
    this.socket.emit('section-server:deleteOne', {
      _id: id,
      token: this.auth.token,
    });
  }
}
