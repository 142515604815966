import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { VoterService } from '../../services/voter.service';
import { DETECTION_STATUS, User } from '../../interfaces/user.interface';
import { ThemeService } from '../../services/theme.service';
import { ToastController } from '@ionic/angular';

import * as _ from 'lodash';
import { filter } from 'rxjs/operators';

type ExtUser = User & { isDetected: boolean; initialDetection: boolean };

@Component({
  selector: 'app-modal-detected-voters',
  templateUrl: './modal-detected-voters.component.html',
  styleUrls: ['./modal-detected-voters.component.scss'],
})
export class ModalDetectedVotersComponent implements OnInit {
  public voters: ExtUser[];
  public loadedVoters: ExtUser[];
  private voterChunks: ExtUser[][];
  public currentChunk: number;
  private votersEvent: Subscription;

  constructor(
    private readonly themeService: ThemeService,
    private readonly toastController: ToastController,
    private readonly voterService: VoterService
  ) {
    this.voters = [];
    this.loadedVoters = [];
    this.voterChunks = [];
    this.currentChunk = 0;
  }

  async handleServerData() {
    this.votersEvent = this.voterService.voters$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data) => {
        this.voters = _.sortBy(
          _.cloneDeep(data).map((item) => {
            const title = Number(item.title);
            item.title = title ? (title as any) : 0;
            return item;
          }),
          'title'
        ).map((user) => {
          let isDetected: boolean =
            user.status.detectionStatus.status == DETECTION_STATUS['DETECTED'];
          return { ...user, ...{ isDetected, initialDetection: isDetected } };
        });
        this.voterChunks = _.chunk(this.voters, 100);
        this.loadedVoters = this.replaceVoters(
          this.loadedVoters,
          _.flatten(_.take(this.voterChunks, this.currentChunk + 1))
        );
      });
  }

  private replaceVoters(source: ExtUser[], update: ExtUser[]): ExtUser[] {
    source = _.uniqBy([...source, ...update], '_id');
    source = _.intersectionBy(source, update, '_id');
    for (let voter of source) {
      let updateItem = _.find(update, { _id: voter._id });
      if (updateItem) _.merge(voter, _.omit(updateItem, '_id'));
    }
    return source;
  }

  public async onCancel(): Promise<void> {
    this.themeService.isModal$.next({
      name: 'detected-voters',
      isOpen: false,
      returns: null,
      action: 'nothing',
    });

    const toast = await this.toastController.create({
      message: 'Saliste del editor, la información fue descartada',
      color: 'warning',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  public onDetected(user: ExtUser) {
    user.isDetected = !user.isDetected;
    user.status.detectionStatus.status = user.isDetected
      ? DETECTION_STATUS['DETECTED']
      : DETECTION_STATUS['UNDETECTED'];
  }

  public async onContinue(): Promise<void> {
    let usersPayload = _.filter(this.voters, (user) => {
      return user.initialDetection != user.isDetected;
    });
    this.themeService.isModal$.next({
      name: 'detected-voters',
      isOpen: false,
      returns: usersPayload,
      action: 'update',
    });
  }

  public onLoadData(): void {
    if (this.voterChunks) {
      this.loadedVoters.push(...this.voterChunks[this.currentChunk]);
      this.currentChunk++;
    }
  }

  ngOnInit(): void {
    this.handleServerData();
  }

  ngOnDestroy(): void {
    this.votersEvent.unsubscribe();
  }
}
