import { Component, OnInit, Input } from '@angular/core';

import * as _ from 'lodash';
import { User, VISIT_STATUS } from 'src/app/interfaces/user.interface';
import { SupportList } from '../../../interfaces/supportList.interface';
import { ThemeService } from '../../../services/theme.service';

@Component({
  selector: 'app-supporter-item',
  templateUrl: './supporter-item.component.html',
  styleUrls: ['./supporter-item.component.scss'],
})
export class SupporterItemComponent implements OnInit {
  @Input('supportList')
  public supportList: SupportList;
  public supportListOwner: User;
  public visitedCount: number;

  constructor(private readonly themeService: ThemeService) {
    this.visitedCount = 0;
  }

  public onMaps(): void {
    this.themeService.isModal$.next({
      name: 'maps',
      isOpen: true,
      payload: this.supportListOwner,
      action: 'nothing',
    });
  }

  ngOnInit() {
    this.supportList.voters.forEach((item) => {
      if (item.status.visitStatus.status == VISIT_STATUS['VISITED'])
        this.visitedCount++;
    });
    if (this.supportList.supportListOwners.length != 0)
      this.supportListOwner = this.supportList.supportListOwners[0];
  }
}
