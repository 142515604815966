import { Component, OnInit, Optional, Self, Input } from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';
import { Option } from '../../interfaces/option.interface';
import * as _ from 'lodash';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements ControlValueAccessor {
  @Input('options') options: Option[];
  @Input('label') label: string;
  @Input('placeholder') placeholder: string;

  public value: any;
  public selected: Option;
  public disabled: boolean;

  public onChange: (event?) => void = (event) => {};
  public onTouched: (value?) => void = (value) => {};

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) this.ngControl.valueAccessor = this;
  }

  public onSelectValue(option: Option): void {
    this.selected = option;
    this.onChange(option.value);
    this.onTouched();
  }

  writeValue(value: string): void {
    this.value = value ? value : '';
    this.selected =
      value == ''
        ? { value: '', title: this.placeholder }
        : _.find(this.options, { value: this.value });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() {}
}
