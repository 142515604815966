import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { BallotService } from 'src/app/services/ballot.service';
import { ThemeService } from 'src/app/services/theme.service';
import { Ballot } from 'src/app/interfaces/ballot.interface';

import * as _ from 'lodash';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-ballots',
  templateUrl: './ballots.component.html',
  styleUrls: ['./ballots.component.scss'],
})
export class BallotsComponent implements OnInit {
  public ballots: Ballot[];
  public assignedBallotOwners: number;
  public assignedPolls: number;
  private ballotSubscription: Subscription;

  constructor(
    private readonly ballotService: BallotService,
    private readonly themeService: ThemeService
  ) {
    this.ballots = [];
    this.assignedPolls = 0;
    this.assignedBallotOwners = 0;
    this.ballotSubscription = this.ballotService.ballots$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data: Ballot[]) => {
        data = _.cloneDeep(data);
        this.ballots = _.uniqBy([...this.ballots, ...data], '_id');
        this.ballots = _.intersectionBy(this.ballots, data, '_id');
        for (let ballot of this.ballots) {
          let update = _.find(data, { _id: ballot._id });
          if (update) _.merge(ballot, _.omit(update, '_id'));
        }

        this.ballots.forEach((item) => {
          this.assignedBallotOwners += item.ballotOwners.length;
          this.assignedPolls += item.poll ? 1 : 0;
        });
      });
  }

  public onParty(): void {
    this.themeService.isModal$.next({
      name: 'party',
      isOpen: true,
      action: 'create',
    });
  }

  public onChatSupporter(): void {
    this.themeService.isModal$.next({
      name: 'chat-supporter',
      isOpen: true,
      action: 'create',
    });
  }

  public onBallotOwner(): void {
    this.themeService.isModal$.next({
      name: 'ballot-owner',
      isOpen: true,
      action: 'create',
    });
  }

  public onBallot(ballot: Ballot): void {
    this.themeService.isModal$.next({
      name: 'ballot',
      isOpen: true,
      payload: _.cloneDeep(ballot),
      action: 'update',
    });
  }

  public onDelete(ballot: Ballot): void {
    this.themeService.isModal$.next({
      name: 'confirm',
      isOpen: true,
      payload: `delete-ballot/${ballot._id}`,
      action: 'delete',
    });
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.ballotSubscription.unsubscribe();
  }
}
