import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-splashscreen-brand',
  templateUrl: './splashscreen-brand.component.html',
  styleUrls: ['./splashscreen-brand.component.scss'],
})
export class SplashscreenBrandComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
