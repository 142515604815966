import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { SocketNameSpace } from '../socket.namespace';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Response } from '../interfaces/response.interface';
import { Auth, User } from '../interfaces/user.interface';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public auth$: BehaviorSubject<Auth>;
  private socket: Socket;

  constructor(
    private readonly toastController: ToastController,
    private readonly geolocationService: Geolocation
  ) {
    this.auth$ = new BehaviorSubject(null);

    this.socket = new SocketNameSpace({
      url: `${environment.server.base_url}/auth`,
      options: {},
    });

    this.observeErrorResponses();
    this.observePrivateResponses();
    if (environment.tracking) this.watchUserGeolocation();
  }

  private observeErrorResponses(): void {
    this.socket
      .fromEvent('exception')
      .pipe(
        filter(({ data }) => {
          return data == 'Not Authenticated';
        })
      )
      .subscribe(() => {
        this.toastController
          .create({
            message: `No se pudo iniciar, vuelva a intentarlo`,
            color: 'danger',
            position: 'top',
            mode: 'ios',
            duration: 6000,
          })
          .then((toast) => {
            toast.present();
            this.auth$.next(null);
          });
      });

    this.socket
      .fromEvent('exception')
      .pipe(
        filter(({ data }) => {
          return data == 'Invalid Token';
        })
      )
      .subscribe(() => {
        this.toastController
          .create({
            message: `El inicio de sesión ha expirado, vuelva a hacerlo`,
            color: 'info',
            position: 'top',
            mode: 'ios',
            duration: 6000,
          })
          .then((toast) => {
            toast.present();
            this.auth$.next(null);
          });
      });
  }

  private observePrivateResponses(): void {
    this.socket
      .fromEvent('auth-client:login')
      .subscribe(({ data }: Response<Auth>) => {
        this.toastController
          .create({
            message: `Bienvenido ${data.firstName}, el sistema se esta iniciando`,
            color: 'success',
            position: 'top',
            mode: 'ios',
            duration: 6000,
          })
          .then((toast) => {
            toast.present();
          });
        this.auth$.next(data);
      });
  }

  public login(user: User): void {
    this.socket.emit('auth-server:login', user);
  }

  private watchUserGeolocation() {
    this.auth$.pipe(filter((data) => data != null)).subscribe((data) => {
      this.geolocationService
        .watchPosition()
        .pipe(
          filter((geoposition: Geoposition) => {
            return geoposition.coords != undefined;
          })
        )
        .subscribe((geoposition: Geoposition) => {
          this.socket.emit('auth-server:geoposition', {
            token: data.token,
            user: data._id,
            timestamp: geoposition.timestamp,
            coords: {
              latitude: geoposition.coords.latitude,
              longitude: geoposition.coords.longitude,
              accuracy: geoposition.coords.accuracy,
              altitude: geoposition.coords.altitude,
              altitudeAccuracy: geoposition.coords.altitudeAccuracy,
              heading: geoposition.coords.heading,
              speed: geoposition.coords.speed,
            },
          });
        });
    });
  }
}
