import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ThemeService } from '../../services/theme.service';
import {
  DETECTION_STATUS,
  User,
  VISIT_STATUS,
} from 'src/app/interfaces/user.interface';
import { VOTING_STATUS } from '../../interfaces/user.interface';
import * as _ from 'lodash';
import { Modal } from 'src/app/interfaces/modal.interface';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-modal-voter',
  templateUrl: './modal-voter.component.html',
  styleUrls: ['./modal-voter.component.scss'],
})
export class ModalVoterComponent implements OnInit {
  public userForm: FormGroup;
  private payload: User;
  private modalEvent: Subscription;

  constructor(
    private readonly themeService: ThemeService,
    private readonly toastController: ToastController
  ) {
    this.modalEvent = this.themeService.isModal$
      .pipe(
        filter(({ isOpen }: Modal) => {
          return isOpen;
        })
      )
      .subscribe(({ payload }) => {
        this.payload = _.cloneDeep(payload);
      });
  }

  public async onCancel(): Promise<void> {
    this.themeService.isModal$.next({
      name: 'voter',
      isOpen: false,
      returns: null,
      action: 'nothing',
    });

    const toast = await this.toastController.create({
      message: 'Saliste del editor, la información fue descartada',
      color: 'warning',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  public async onContinueError(): Promise<void> {
    const toast = await this.toastController.create({
      message: 'No haz completado la información correctamente',
      color: 'danger',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  public async onContinue(): Promise<void> {
    let formPayload = {
      ...this.userForm.value,
      ...{
        status: {
          detectionStatus: {
            status: this.userForm.value.detectionStatus ? 0 : 1,
          },
          votingStatus: { status: this.userForm.value.votingStatus ? 0 : 1 },
          visitStatus: { status: this.userForm.value.visitStatus ? 0 : 1 },
        },
      },
    };
    delete formPayload.detectionStatus;
    delete formPayload.votingStatus;
    this.themeService.isModal$.next({
      name: 'voter',
      isOpen: false,
      returns: this.payload
        ? { _id: this.payload._id, ...formPayload }
        : formPayload,
      action: this.payload ? 'update' : 'create',
    });
  }

  private initForm(): void {
    this.userForm = new FormGroup({
      title: new FormControl('1', [Validators.required]),
      firstName: new FormControl('Marco', [Validators.required]),
      lastName: new FormControl('Fabian', [Validators.required]),
      votingId: new FormControl('123456789012345678', [Validators.required]),
      phone: new FormControl('4493667122'),
      detectionStatus: new FormControl(false),
      votingStatus: new FormControl(false),
      visitStatus: new FormControl(false),
      address: new FormGroup({
        county: new FormControl('Aguascalientes'),
        city: new FormControl('España'),
        streetName: new FormControl('Av Soldador'),
        streetNumber: new FormControl('2015'),
        apartmentName: new FormControl('D'),
        zip: new FormControl('20001'),
      }),
    });
  }

  ngOnInit(): void {
    this.initForm();
    if (this.payload && this.payload.status) {
      let [detectionStatus, votingStatus, visitStatus] = [
        this.payload.status.detectionStatus.status,
        this.payload.status.votingStatus.status,
        this.payload.status.visitStatus.status,
      ];
      delete this.payload.status.detectionStatus;
      delete this.payload.status.votingStatus;
      this.userForm.patchValue({
        ...this.payload,
        ...{ detectionStatus: detectionStatus == DETECTION_STATUS['DETECTED'] },
        ...{ votingStatus: votingStatus == VOTING_STATUS['VOTED'] },
        ...{ visitStatus: visitStatus == VISIT_STATUS['VISITED'] },
      });
    }
  }
  ngOnDestroy(): void {
    this.modalEvent.unsubscribe();
  }
}
