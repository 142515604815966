import { ModalsModule } from './modals/modals.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';

import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { SplashscreenModule } from './splashscreen/splashscreen.module';
import { ComponentsModule } from './components/components.module';
import { PagesModule } from './pages/pages.module';
import { PipesModule } from './pipes/pipes.module';

const config: SocketIoConfig = {
  url: environment.server.base_url,
  options: {},
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    NgbModule,
    PipesModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    SocketIoModule.forRoot(config),
    AgmCoreModule.forRoot({
      apiKey: environment.server.maps_key + '&libraries=visualization',
    }),
    PagesModule,
    ModalsModule,
    SplashscreenModule,
    AuthModule,
    AppRoutingModule,
    ComponentsModule,
  ],
  providers: [
    Geolocation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
