import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplashscreenBrandComponent } from './splashscreen-brand/splashscreen-brand.component';
import { SplashscreenCuadrosoftComponent } from './splashscreen-cuadrosoft/splashscreen-cuadrosoft.component';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  declarations: [SplashscreenBrandComponent, SplashscreenCuadrosoftComponent],
  exports: [SplashscreenBrandComponent, SplashscreenCuadrosoftComponent],
  imports: [CommonModule, ComponentsModule],
})
export class SplashscreenModule {}
