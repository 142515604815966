import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/interfaces/user.interface';
import { ChatMessage } from '../../../../interfaces/chat.interface';
import { ChatService } from '../../../../services/chat.service';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit {
  public color: string;

  @Input('members')
  public members: [User & { color: string }];

  @Input('message')
  public message: ChatMessage;

  constructor(private readonly chatService: ChatService) {}

  ngOnInit() {
    this.color = this.members.find((item) => {
      return item._id == this.message.sender._id;
    })
      ? this.members.find((item) => {
          return item._id == this.message.sender._id;
        }).color
      : 'primary';
  }
}
