import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { Chat } from '../../../../interfaces/chat.interface';
import { ChatService } from '../../../../services/chat.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-chat-bar',
  templateUrl: './chat-bar.component.html',
  styleUrls: ['./chat-bar.component.scss'],
})
export class ChatBarComponent implements OnInit {
  @Input('chat')
  public chat: Chat;
  public chatEvent: Subscription;
  public chatForm: FormGroup;

  constructor(private readonly chatService: ChatService) {}

  public onEnter() {
    let message = this.chatForm.value.message;
    this.chatForm.reset();
    this.chatService.sendMessage(this.chat, message);
  }

  ngOnInit() {
    this.chatForm = new FormGroup({
      message: new FormControl('', Validators.required),
    });
  }
}
