import { Component, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AccessService } from './services/access.service';
import { ThemeService } from './services/theme.service';
import { AuthService } from './services/auth.service';

import * as _ from 'lodash';

import { Auth } from './interfaces/user.interface';
import { PollService } from './services/poll.service';
import { PollOwnerService } from './services/poll-owner.service';
import { SectionService } from './services/section.service';
import { ChatSupporterService } from './services/chat-supporter.service';
import { VoterService } from './services/voter.service';
import { PartyService } from './services/party.service';
import { BallotOwnerService } from './services/ballot-owner.service';
import { BallotService } from './services/ballot.service';
import { SupportListOwnerService } from './services/support-list-owner.service';
import { SupportListService } from './services/support-list.service';
import { ChatService } from './services/chat.service';
import { AdminService } from './services/admin.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
  public isLoading: boolean;
  public isLogin: boolean;
  public isPanSplash: boolean;
  public isCuadrosoftSplash: boolean;

  private accessSubscription: Subscription;
  private modalSubscription: Subscription;
  private authSubscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly themeService: ThemeService,
    private readonly accessService: AccessService,
    private readonly adminService: AdminService,
    private readonly ballotService: BallotService,
    private readonly ballotOwnerService: BallotOwnerService,
    private readonly chatService: ChatService,
    private readonly chatSupporterService: ChatSupporterService,
    private readonly pollService: PollService,
    private readonly pollOwnerService: PollOwnerService,
    private readonly supportListService: SupportListService,
    private readonly supportListOwnerService: SupportListOwnerService,
    private readonly voterService: VoterService,
    private readonly partyService: PartyService,
    private readonly sectionService: SectionService
  ) {
    this.isLoading = environment.splashScreen.show;
    this.isPanSplash = environment.splashScreen.show;
    this.isCuadrosoftSplash = environment.splashScreen.show;
    if (environment.splashScreen.show) this.handleSplashScreen();

    this.handleFirstTimeAccess();
    this.handleAuthentication();
  }

  private handleAuthentication(): void {
    this.isLogin = false;
    this.authSubscription = this.authService.auth$.subscribe((data: Auth) => {
      this.isLogin = !_.isEmpty(data);
      if (this.isLogin) this.router.navigate([environment.defaultRoute]);
      else this.router.navigate([]);
    });
  }

  private handleFirstTimeAccess(): void {
    this.accessSubscription = this.accessService.access$.subscribe(
      (data: any[]) => {
        if (data && data.length == 0)
          this.themeService.isModal$.next({
            name: 'access',
            isOpen: true,
            action: 'create',
          });
      }
    );
  }

  private handleSplashScreen(): void {
    setTimeout(() => {
      this.isPanSplash = false;
      setTimeout(() => {
        this.isCuadrosoftSplash = false;
        setTimeout(() => {
          this.isLoading = false;
        }, environment.splashScreen.splashFadeDuration);
      }, environment.splashScreen.cuadrosoftSplashDuration);
    }, environment.splashScreen.panSplashDuration);
  }

  ngOnDestroy(): void {
    this.accessSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
    this.modalSubscription.unsubscribe();
  }
}
