import { Component, Input, OnInit } from '@angular/core';
import { Chat } from '../../../interfaces/chat.interface';
import * as _ from 'lodash';

@Component({
  selector: 'app-chat-contact-item',
  templateUrl: './chat-contact-item.component.html',
  styleUrls: ['./chat-contact-item.component.scss'],
})
export class ChatContactItemComponent implements OnInit {
  @Input('chat')
  public chat: Chat;

  constructor() {}

  public take(array: any, n: number): any[] {
    return _.takeRight(array, n);
  }

  ngOnInit() {}
}
