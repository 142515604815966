import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  public loginForm: FormGroup;
  constructor(
    private readonly authService: AuthService,
    private readonly toastController: ToastController
  ) {}

  public onContinue() {
    let payload = this.loginForm.value;
    delete payload.remember;
    this.authService.login(payload);
  }

  public async onContinueError() {
    const toast = await this.toastController.create({
      message: `Te falta llenar el campo de usuario o contraseña`,
      color: 'warning',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      remember: new FormControl(true),
    });
  }
}
