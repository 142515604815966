import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { SocketNameSpace } from '../socket.namespace';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';
import { Response } from '../interfaces/response.interface';
import { Auth, User } from '../interfaces/user.interface';
import { AuthService } from './auth.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public users$: BehaviorSubject<User[]>;
  private auth: Auth;
  private socket: Socket;

  constructor(private readonly authService: AuthService) {
    this.users$ = new BehaviorSubject(null);
    this.socket = new SocketNameSpace({
      url: `${environment.server.base_url}/user`,
      options: {},
    });
    this.observeServerData();
  }
  private observeServerData(): void {
    this.authService.auth$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data: Auth) => {
        this.auth = data;
        this.socket.emit('user-server:findAll', { token: this.auth.token });
        this.socket
          .fromEvent('user-client:findAll')
          .subscribe(({ data }: Response<User[]>) => {
            this.users$.next(data);
          });
      });
  }
}
