import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { PollService } from 'src/app/services/poll.service';
import { ThemeService } from 'src/app/services/theme.service';
import { Poll } from 'src/app/interfaces/poll.interface';

import * as _ from 'lodash';

@Component({
  selector: 'app-polls',
  templateUrl: './polls.component.html',
  styleUrls: ['./polls.component.scss'],
})
export class PollsComponent implements OnInit {
  public polls: Poll[];
  public assignedPollOwners: number;
  public assignedSections: number;
  private pollSubscription: Subscription;

  constructor(
    private readonly pollService: PollService,
    private readonly themeService: ThemeService
  ) {
    this.polls = [];
    this.assignedSections = 0;
    this.assignedPollOwners = 0;
    this.pollSubscription = this.pollService.polls$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data: Poll[]) => {
        data = _.cloneDeep(data);
        this.polls = _.uniqBy([...this.polls, ...data], '_id');
        this.polls = _.intersectionBy(this.polls, data, '_id');
        for (let poll of this.polls) {
          let update = _.find(data, { _id: poll._id });
          if (update) _.merge(poll, _.omit(update, '_id'));
        }

        let assignedPollOwners = [];
        let assignedSections = [];
        this.polls.map((item: Poll) => {
          assignedPollOwners.push(item.pollOwners);
          assignedSections.push(item.section);
        });
        this.assignedPollOwners = _.uniqBy(
          _.flatten(assignedPollOwners),
          '_id'
        ).length;
        this.assignedSections = _.uniqBy(assignedSections, '_id').length;
      });
  }

  public onPollOwner(): void {
    this.themeService.isModal$.next({
      name: 'poll-owner',
      isOpen: true,
      action: 'create',
    });
  }

  public onSection(): void {
    this.themeService.isModal$.next({
      name: 'section',
      isOpen: true,
      action: 'create',
    });
  }

  public onVoter(): void {
    this.themeService.isModal$.next({
      name: 'voter',
      isOpen: true,
      action: 'create',
    });
  }

  public onChatSupporter(): void {
    this.themeService.isModal$.next({
      name: 'chat-supporter',
      isOpen: true,
      action: 'create',
    });
  }

  public onPoll(poll?: Poll): void {
    this.themeService.isModal$.next({
      name: 'poll',
      isOpen: true,
      payload: _.cloneDeep(poll),
      action: poll ? 'update' : 'create',
    });
  }

  public onDelete(poll: Poll): void {
    this.themeService.isModal$.next({
      name: 'confirm',
      isOpen: true,
      payload: `delete-poll/${poll._id}`,
      action: 'delete',
    });
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.pollSubscription.unsubscribe();
  }
}
