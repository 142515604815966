import { Component, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ThemeService } from 'src/app/services/theme.service';
import { PasswordValidators } from 'src/app/validators/password.validator';
import { Modal } from 'src/app/interfaces/modal.interface';
import { datePattern } from '../../validators/pattern.validator';

import * as _ from 'lodash';

@Component({
  selector: 'app-modal-access',
  templateUrl: './modal-access.component.html',
  styleUrls: ['./modal-access.component.scss'],
})
export class ModalAccessComponent implements OnInit {
  public payload: any;
  public userForm: FormGroup;
  private modalEvent: Subscription;

  constructor(
    private readonly themeService: ThemeService,
    private readonly toastController: ToastController
  ) {
    this.modalEvent = this.themeService.isModal$.subscribe(
      ({ payload }: Modal) => {
        this.payload = _.cloneDeep(payload);
      }
    );
  }

  public async onContinueError(): Promise<void> {
    const toast = await this.toastController.create({
      message: 'No haz completado la información correctamente',
      color: 'danger',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  public async onContinue(): Promise<void> {
    const formPayload = this.userForm.value;
    delete formPayload.confirmPassword;
    this.themeService.isModal$.next({
      name: 'access',
      isOpen: false,
      returns: formPayload,
      action: 'create',
    });
  }

  private initForm(): void {
    this.userForm = new FormGroup({
      workday: new FormControl(
        new Date().toJSON().slice(0, 10).split('-').reverse().join('/'),
        [Validators.required, Validators.pattern(datePattern)]
      ),
      firstName: new FormControl('Miguel', [Validators.required]),
      lastName: new FormControl('Romero', [Validators.required]),
      username: new FormControl('miguelromero', [Validators.required]),
      email: new FormControl('miguel@cuadrosoft.com', [Validators.required]),
      phone: new FormControl('4493667122', [Validators.required]),
      password: new FormControl('Kiloopen.2', [Validators.required]),
      confirmPassword: new FormControl(
        'Kiloopen.2',
        [Validators.required],
        PasswordValidators.passwordMatchValidator.bind(this)
      ),
    });
  }

  ngOnInit(): void {
    this.initForm();
  }
  ngOnDestroy(): void {
    this.modalEvent.unsubscribe();
  }
}
