import { Pipe, PipeTransform } from '@angular/core';
import { ELECTION_TYPE } from '../interfaces/ballot.interface';

@Pipe({
  name: 'election',
})
export class ElectionPipe implements PipeTransform {
  transform(value: ELECTION_TYPE): string {
    switch (ELECTION_TYPE[value]) {
      case 'LOCAL_REPRESENTATIVE':
        return 'Diputado Local';
      case 'MAYOR':
        return 'Alcalde';
      default:
        return 'Ninguno';
    }
  }
}
