import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brand-hugo',
  templateUrl: './brand-hugo.component.html',
  styleUrls: ['./brand-hugo.component.scss'],
})
export class BrandHugoComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
