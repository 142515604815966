import { Component, OnInit, Optional, Self, Input } from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
})
export class CheckBoxComponent implements ControlValueAccessor {
  @Input('text')
  public text: string;

  public value: boolean = false;
  public disabled: boolean = false;
  public onChange: (event?) => void = (event) => {};
  public onTouched: (value?) => void = (value) => {};

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) this.ngControl.valueAccessor = this;
  }

  writeValue(checked: boolean): void {
    this.value = checked == true ? checked : false;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
