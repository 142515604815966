import { Address } from './address.interface';
import { SupportList } from './supportList.interface';
import { Access } from './access.interface';
import { GeolocationPosition } from '@capacitor/core';

export enum ROLES {
  'MASTER',
  'ADMIN',
  'CHAT_SUPPORTER',
  'POLL_OWNER',
  'BALLOT_OWNER',
  'SUPPORT_LIST_OWNER',
  'VOTER',
}

export enum NETWORK_STATUS {
  'ONLINE',
  'OFFLINE',
}

export enum DETECTION_STATUS {
  'DETECTED',
  'UNDETECTED',
}

export enum VISIT_STATUS {
  'VISITED',
  'NOT-VISITED',
}

export enum VOTING_STATUS {
  'VOTED',
  'NOT-VOTED',
}

export enum WORKING_STATUS {
  'DISCONNECTED',
  'WAITING',
  'WORKING',
  'FINISHED',
}

export enum MOVEMENT_STATUS {
  'DISCONNECTED',
  'STATIC',
  'MOVING',
}

export interface UserStatus {
  networkStatus: {
    status: NETWORK_STATUS;
    timestamp: number;
  };
  detectionStatus: {
    status: DETECTION_STATUS;
    timestamp: number;
  };
  visitStatus: {
    status: VISIT_STATUS;
    timestamp: number;
  };
  votingStatus: {
    status: VOTING_STATUS;
    timestamp: number;
  };
  workingStatus: {
    status: WORKING_STATUS;
    timestamp: number;
  };
  movementStatus: {
    status: MOVEMENT_STATUS;
    timestamp: number;
  };
}

export interface User {
  _id?: string;
  role: ROLES;
  socketId: string;
  isBackup: boolean;
  avatar: string;
  votingId: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  username: string;
  password: string;
  pin: string;
  address: Address;
  status: UserStatus;
  geolocationPositions: GeolocationPosition[];
  supportList: SupportList & string;
  access: Access[] & string[];
}

export type Auth = { token: string } & User;
