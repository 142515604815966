import {
  Component,
  OnInit,
  Input,
  Self,
  Optional,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements ControlValueAccessor {
  @Input('value')
  public value: string;

  @Input('type')
  public type: string;

  @Input('icon')
  public icon: string;

  @Input('iconPath')
  public iconPath: string;

  @Input('placeholder')
  public placeholder: string;

  @Input('label')
  public label: string;

  @Input('color')
  public color: string;

  @Output('formChange') formChange: EventEmitter<string>;
  @Output('enter') enterEvent: EventEmitter<boolean>;

  public disabled: boolean = false;
  public onChange: (event?) => void = (event) => {};
  public onTouched: (value?) => void = (value) => {};

  @ViewChild('input')
  public inputRef: ElementRef;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    this.formChange = new EventEmitter();
    this.enterEvent = new EventEmitter();
    if (this.ngControl != null) this.ngControl.valueAccessor = this;
    this.value = '';
    this.placeholder = '';
  }

  public onEnter() {
    this.enterEvent.emit(true);
  }

  public inputEvent(event) {
    let isDecimal = /^(\d+\.?\d{0,9}|\.\d{0,9})$/;

    if (this.type == 'phone' && event?.target?.value) {
      let x = event.target.value
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      event.target.value = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    } else if (this.type == 'number' && !isDecimal.test(event.target.value)) {
      event.target.value = event.target.value.substring(
        0,
        event.target.value.length - 1
      );
    }

    this.formChange.emit(event.target.value);
    this.onChange(event.target.value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    if (this.type == 'phone' && value) {
      var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.value = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      if (this.inputRef) this.inputRef.nativeElement.value = this.value;
    } else if (this.type == 'number') {
      value = value.replace(/\D(!?\.)/g, '');
      let x = value.match(/(\d*\.?\d*)/g);
      this.value = x[0];
      if (this.inputRef) this.inputRef.nativeElement.value = this.value;
    } else {
      this.value = value ? value : '';
      if (this.inputRef) this.inputRef.nativeElement.value = this.value;
    }
  }
}
