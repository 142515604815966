import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ThemeService } from 'src/app/services/theme.service';
import { PasswordValidators } from 'src/app/validators/password.validator';
import { Modal } from 'src/app/interfaces/modal.interface';
import * as _ from 'lodash';

@Component({
  selector: 'app-modal-admin',
  templateUrl: './modal-admin.component.html',
  styleUrls: ['./modal-admin.component.scss'],
})
export class ModalAdminComponent implements OnInit {
  public payload: any;
  public userForm: FormGroup;
  private modalEvent: Subscription;

  constructor(
    private readonly themeService: ThemeService,
    private readonly toastController: ToastController
  ) {
    this.modalEvent = this.themeService.isModal$.subscribe(
      ({ payload }: Modal) => {
        this.payload = _.cloneDeep(payload);
      }
    );
  }

  public async onCancel(): Promise<void> {
    this.themeService.isModal$.next({
      name: 'admin',
      isOpen: false,
      returns: null,
      action: 'nothing',
    });

    const toast = await this.toastController.create({
      message: 'Saliste del editor, la información fue descartada',
      color: 'warning',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  public async onContinueError(): Promise<void> {
    const toast = await this.toastController.create({
      message: 'No haz completado la información correctamente',
      color: 'danger',
      position: 'top',
      mode: 'ios',
      duration: 6000,
    });
    toast.present();
  }

  public async onContinue(): Promise<void> {
    const formPayload = this.userForm.value;
    this.themeService.isModal$.next({
      name: 'admin',
      isOpen: false,
      returns: this.payload
        ? { _id: this.payload._id, ...formPayload }
        : formPayload,
      action: this.payload ? 'update' : 'create',
    });
  }

  private initForm(): void {
    this.userForm = new FormGroup({
      firstName: new FormControl('Juan Carlos', [Validators.required]),
      lastName: new FormControl('Gálvez Lopez', [Validators.required]),
      email: new FormControl('correo@hugistas.com', [Validators.required]),
      phone: new FormControl('4493667122', [Validators.required]),
      username: new FormControl('juangalvez', [Validators.required]),
      password: new FormControl('Kiloopen.2', [Validators.required]),
      confirmPassword: new FormControl(
        'Kiloopen.2',
        [Validators.required],
        PasswordValidators.passwordMatchValidator.bind(this)
      ),
      address: new FormGroup({
        county: new FormControl('Aguascalientes'),
        city: new FormControl('El Puerto'),
        streetName: new FormControl('Av. Las Americas'),
        streetNumber: new FormControl('104'),
        apartmentName: new FormControl('C'),
        zip: new FormControl('20358'),
      }),
    });
  }

  ngOnInit(): void {
    this.initForm();
    if (this.payload) this.userForm.patchValue(this.payload);
  }

  ngOnDestroy(): void {
    this.modalEvent.unsubscribe();
    if (this.payload) this.userForm.patchValue(this.payload);
  }
}
