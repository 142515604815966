import { Component, Input, OnInit } from '@angular/core';
import { Ballot } from 'src/app/interfaces/ballot.interface';
import * as _ from 'lodash';

@Component({
  selector: 'app-ballot-item',
  templateUrl: './ballot-item.component.html',
  styleUrls: ['./ballot-item.component.scss'],
})
export class BallotItemComponent implements OnInit {
  @Input('ballot')
  public ballot: Ballot;

  public votes: number;

  constructor() {
    this.votes = 0;
  }

  public take(array: any, n: number): any[] {
    return _.takeRight(array, n);
  }

  ngOnInit() {
    if (
      this.ballot &&
      this.ballot.electionResults &&
      this.ballot.electionResults.length != 0
    ) {
      this.ballot.electionResults.map(({ partyResults }) => {
        partyResults.forEach((item) => {
          this.votes += item.votes;
        });
      });
    }
  }
}
