import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input('disabled')
  public disabled: boolean;

  @Input('text')
  public text: string;

  @Input('align')
  public align: string;

  @Input('color')
  public color: string;

  @Input('icon')
  public icon: string;

  @Input('size')
  public size: string;

  @Input('position')
  public position: string;

  @Output('AuthorizedEvent')
  authorizedEvent: EventEmitter<boolean>;

  @Output('UnauthorizedEvent')
  unauthorizedEvent: EventEmitter<boolean>;

  constructor() {
    this.authorizedEvent = new EventEmitter();
    this.unauthorizedEvent = new EventEmitter();
  }

  public onClick() {
    if (!this.disabled) this.authorizedEvent.emit(true);
    else this.unauthorizedEvent.emit(true);
  }

  ngOnInit() {}
}
