import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { SupportListService } from '../../services/support-list.service';
import { SupportList } from '../../interfaces/supportList.interface';
import { ThemeService } from '../../services/theme.service';

import * as _ from 'lodash';
import { DETECTION_STATUS } from 'src/app/interfaces/user.interface';

@Component({
  selector: 'app-supporters',
  templateUrl: './supporters.component.html',
  styleUrls: ['./supporters.component.scss'],
})
export class SupportersComponent implements OnInit {
  public supportLists: SupportList[];
  public assignedDetectedVoters: number;
  public assignedSupportListOwner: number;

  private supportListEvent: Subscription;

  constructor(
    private readonly supportListService: SupportListService,
    private readonly themeService: ThemeService
  ) {
    this.supportLists = [];
    this.assignedDetectedVoters = 0;
    this.assignedSupportListOwner = 0;
    this.handleDataService();
  }

  private handleDataService(): void {
    this.supportListEvent = this.supportListService.supportLists$
      .pipe(
        filter((data) => {
          return data != null;
        })
      )
      .subscribe((data: SupportList[]) => {
        data = _.cloneDeep(data);
        this.supportLists = _.uniqBy([...this.supportLists, ...data], '_id');
        this.supportLists = _.intersectionBy(this.supportLists, data, '_id');
        for (let supportList of this.supportLists) {
          let update = _.find(data, { _id: supportList._id });
          if (update) _.merge(supportList, _.omit(update, '_id'));
        }

        this.supportLists.map((supportList) => {
          supportList.voters.map((user) => {
            if (user.supportList) this.assignedSupportListOwner++;
            if (
              user.status.detectionStatus.status == DETECTION_STATUS['DETECTED']
            )
              this.assignedDetectedVoters++;
          });
        });
      });
  }

  public onVoter(): void {
    this.themeService.isModal$.next({
      name: 'voter',
      isOpen: true,
      action: 'create',
    });
  }

  public onDetected(): void {
    this.themeService.isModal$.next({
      name: 'detected-voters',
      isOpen: true,
      action: 'update',
    });
  }

  public onSupportListOwner(): void {
    this.themeService.isModal$.next({
      name: 'support-list-owner',
      isOpen: true,
      action: 'update',
    });
  }

  public onChatSupporter(): void {
    this.themeService.isModal$.next({
      name: 'chat-supporter',
      isOpen: true,
      action: 'create',
    });
  }

  public onSupportList(supportList?: SupportList): void {
    this.themeService.isModal$.next({
      name: 'support-list',
      isOpen: true,
      payload: supportList,
      action: supportList ? 'update' : 'create',
    });
  }

  public onDelete(supportList: SupportList): void {
    this.themeService.isModal$.next({
      name: 'confirm',
      isOpen: true,
      payload: `delete-support-list/${supportList._id}`,
      action: 'delete',
    });
  }

  ngOnInit() {}
  ngOnDestroy() {
    this.supportListEvent.unsubscribe();
  }
}
